import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';

import type {
  TEnvironment,
  EnvironmentProviderProps,
  LinkNames,
} from './environment.types';
import {
  ConfiguredEnv,
  ApiNames,
  configuredEnvs,
  envPrefixes,
  linksConfig,
} from './environment.constants';

const EnvironmentContext = createContext<TEnvironment | null>(null);

export function EnvironmentProvider({ children, applicationName }: EnvironmentProviderProps): JSX.Element {
  const envName = useMemo(
    (): ConfiguredEnv => {
      const { origin } = window.location;
      return configuredEnvs.find((name) => origin.includes(envPrefixes[name])) as ConfiguredEnv;
    },
    [],
  );
  const envPrefix = useMemo(() => (envPrefixes[envName]), [envName]);

  const [productName, domain] = useMemo(
    (): [string, string] => {
      const { origin } = window.location;
      return origin.includes('omo-tech') ? ['OMO', 'omo-tech.com'] : ['Pushwoosh', 'pushwoosh.com'];
    },
    [],
  );

  const origins = useMemo(
    () => ({
      [ApiNames.GO]: `https://go${envPrefix}.pushwoosh.com`,
      [ApiNames.SSO]: `https://sso${envPrefix}.${domain}`,
      [ApiNames.USER_API]: `https://user-api${
        envName === ConfiguredEnv.PRODUCTION ? '.svc-nue' : envPrefixes[envName]
      }.pushwoosh.com`,
      [ApiNames.USER_ACTIONS_API]: `https://user-action${
        envName === ConfiguredEnv.PRODUCTION ? '.svc-nue' : envPrefixes[envName]
      }.pushwoosh.com`,
    }),
    [envName, envPrefix, domain],
  );

  const getLinkByName = useCallback(
    (linkName: LinkNames) => linksConfig[applicationName][linkName],
    [applicationName],
  );

  const redirectToApp = useCallback((path = '') => { window.location.href = `https://app${envPrefix}.pushwoosh.com${path}`; }, [envPrefix]);

  const ProviderValues = useMemo(() => ({
    getLinkByName,
    redirectToApp,
    origins,
    productName,
  }), [getLinkByName, redirectToApp, origins, productName]);

  return (
    <EnvironmentContext.Provider value={ProviderValues}>
      {children}
    </EnvironmentContext.Provider>
  );
}

export function useEnvironment(): TEnvironment {
  const environment = useContext(EnvironmentContext);
  if (!environment) {
    throw new Error('Environment is not defined! Please check what are you using useSession in a child of EnvironmentProvider!');
  }

  return environment;
}
