function getProductName(): string {
  if (origin.includes('sso.omo-tech')) {
    return 'OMO Grow Management Platform';
  }

  return 'Pushwoosh';
}

function getProductIcons(): string {
  if (origin.includes('sso.omo-tech')) {
    return `
      <link rel="shortcut icon" href="/static/favicon-omo.png">
      <link rel="apple-touch-icon" sizes="180x180" href="/static/favicon-omo.png" />
      <link rel="mask-icon" href="/static/safari-pinned-tab-omo.svg" color="#0F4DBA" />
    `;
  }

  return `
    <link rel="apple-touch-icon" sizes="180x180" href="/static/apple-touch-icon-pw.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon-16x16.png" />
    <link rel="manifest" href="/static/site.webmanifest" />
    <link rel="mask-icon" href="/static/safari-pinned-tab-pw.svg" color="#0077ff" />
  `;
}

export function updateHTML(): void {
  const htmlCollectionTitle = document.getElementsByTagName('title');
  const [title] = Array.from(htmlCollectionTitle);
  if (title && title.innerText) {
    title.innerText = getProductName();
  }

  const htmlCollectionHead = document.getElementsByTagName('head');
  const [head] = Array.from(htmlCollectionHead);
  if (head && head.innerText) {
    head.innerHTML += getProductIcons();
  }
}
