import type { LinkNames } from '~/src/contexts/environment';
import type { Route } from './RedirectV2.types';

export const internalRoutes = (getLinkByName: (linkName: LinkNames) => string): Route[] => [
  {
    pattern: '/v2/login',
    redirect: getLinkByName('login'),
  },
  {
    pattern: '/v2/createAccount',
    redirect: getLinkByName('register'),
  },
  {
    pattern: '/v2/selectAccount',
    redirect: getLinkByName('selectAccount'),
  },
  {
    pattern: '/v2/confirmationCode',
    redirect: getLinkByName('confirm'),
  },
  {
    pattern: '/v2/register',
    redirect: getLinkByName('register'),
  },
  {
    pattern: '/v2/create-password/:activationToken/:email',
    redirect: '/cp/create-password/:activationToken/:email',
  },
];

export const externalRoutes = [
  // account
  {
    pattern: '/v2/account',
    redirect: '/account/info',
  },
  {
    pattern: '/v2/account/edit',
    redirect: '/account/edit-profile',
  },
  {
    pattern: '/v2/account/access',
    redirect: '/account/users-and-groups',
  },
  {
    pattern: '/v2/account/paymentMethods',
    redirect: '/account/payment-methods',
  },
  {
    pattern: '/v2/account/orders',
    redirect: '/account/payment-history',
  },
  // upgrade
  {
    pattern: '/v2/products',
    redirect: '/products',
  },
  {
    pattern: '/v2/products/buyProduct/:productType',
    redirect: '/products/buyProduct/:productType',
  },
  {
    pattern: '/v2/products/billingOrder/:orderCode',
    redirect: '/products/billingOrder/:orderCode',
  },
  // message history
  {
    pattern: '/v2/notifications',
    redirect: '/notifications',
  },
  {
    pattern: '/v2/notifications/statistics/:uid',
    redirect: '/notifications/statistics/:uid',
  },
  {
    pattern: '/v2/notifications/email-statistics/:uid',
    redirect: '/notifications/email-statistics/:uid',
  },
  {
    pattern: '/v2/notifications/abTest/:uid',
    redirect: '/notifications/abTest/:uid',
  },
  {
    pattern: '/v2/notifications/compare',
    redirect: '/notifications/compare',
  },
  // messaging statistics
  {
    pattern: '/v2/stats',
    redirect: '/stats',
  },
  // applications
  {
    pattern: '/v2/applications',
    redirect: '/projects',
  },
  {
    pattern: '/v2/applications/:applicationCode',
    redirect: '/applications/:applicationCode/push-notification',
  },
  {
    pattern: '/v2/applications/:applicationCode/send-email/:presetCode?',
    redirect: '/applications/:applicationCode/email/send/:presetCode?',
  },
  {
    pattern: '/v2/applications/:applicationCode/subscribers',
    redirect: '/applications/:applicationCode/subscribers',
  },
  {
    pattern: '/v2/applications/:applicationCode/group-permissions',
    redirect: '/applications/:applicationCode/application-permissions',
  },
  {
    pattern: '/v2/applications/:applicationCode/csvPush',
    redirect: '/applications/:applicationCode/push-notification/csvPush',
  },
  {
    pattern: '/v2/applications/:applicationCode/rss',
    redirect: '/applications/:applicationCode/push-notification/rss',
  },
  {
    pattern: '/v2/applications/:applicationCode/configure',
    redirect: '/applications/:applicationCode/configure',
  },
  {
    pattern: '/v2/applications/:applicationCode/configure/ios',
    redirect: '/applications/:applicationCode/configure/ios',
  },
  {
    pattern: '/v2/applications/:applicationCode/configure/email',
    redirect: '/applications/:applicationCode/configure/email',
  },
  {
    pattern: '/v2/applications/:applicationCode/configure/email/edit',
    redirect: '/applications/:applicationCode/configure/edit/edit',
  },
  {
    pattern: '/v2/applications/:applicationCode/configure/web',
    redirect: '/applications/:applicationCode/configure/web',
  },
  {
    pattern: '/v2/applications/:applicationCode/configure/android',
    redirect: '/applications/:applicationCode/configure/android',
  },
  {
    pattern: '/v2/applications/:applicationCode/testdevices',
    redirect: '/applications/:applicationCode/test-devices',
  },
  {
    pattern: '/v2/applications/:applicationCode/campaigns',
    redirect: '/applications/:applicationCode/messaging-statistics/campaigns-statistic',
  },
  {
    pattern: '/v2/applications/:applicationCode/campaigns/:campaignCode/statistics',
    redirect: '/applications/:applicationCode/messaging-statistics/campaigns-statistic/:campaignCode/statistics',
  },
  {
    pattern: '/v2/applications/:applicationCode/campaigns/:campaignCode/edit',
    redirect: '/applications/:applicationCode/messaging-statistics/campaigns-statistic',
  },
  {
    pattern: '/v2/applications/:applicationCode/campaigns/add',
    redirect: '/applications/:applicationCode/messaging-statistics/campaigns-statistic',
  },
  {
    pattern: '/v2/applications/:applicationCode/presets',
    redirect: '/applications/:applicationCode/presets',
  },
  {
    pattern: '/v2/applications/:applicationCode/deeplinks',
    redirect: '/applications/:applicationCode/deeplinks',
  },
  {
    pattern: '/v2/applications/:applicationCode/deeplinks/:deeplinkCode/edit',
    redirect: '/applications/:applicationCode/deeplinks/:deeplinkCode/edit',
  },
  {
    pattern: '/v2/applications/:applicationCode/deeplinks/add',
    redirect: '/applications/:applicationCode/deeplinks/add',
  },
  {
    pattern: '/v2/applications/:applicationCode/inapp-messages',
    redirect: '/applications/:applicationCode/inapp-messages',
  },
  {
    pattern: '/v2/applications/:applicationCode/inapp-messages/:inAppCode/edit',
    redirect: '/applications/:applicationCode/inapp-messages/:inAppCode/edit',
  },
  {
    pattern: '/v2/applications/:applicationCode/inapp-messages/:businessCaseName/:inAppCode/edit',
    redirect: '/applications/:applicationCode/inapp-messages/:businessCaseName/:inAppCode/edit',
  },
  {
    pattern: '/v2/applications/:applicationCode/inapp-messages/:businessCaseName/add',
    redirect: '/applications/:applicationCode/inapp-messages/:businessCaseName/add',
  },
  {
    pattern: '/v2/applications/:applicationCode/inapp-messages/add',
    redirect: '/applications/:applicationCode/inapp-messages/add',
  },
  {
    pattern: '/v2/applications/:applicationCode/inapp-messages/:inAppCode/statistics',
    redirect: '/applications/:applicationCode/inapp-messages/:inAppCode/statistics',
  },
  {
    pattern: '/v2/applications/:applicationCode/statistics',
    redirect: '/applications/:applicationCode/statistics',
  },
  {
    pattern: '/v2/applications/:applicationCode/geozones',
    redirect: '/applications/:applicationCode/push-notification/geozones',
  },
  {
    pattern: '/v2/applications/:applicationCode/autopushes',
    redirect: '/applications/:applicationCode/push-notification/autopushes',
  },
  {
    pattern: '/v2/applications/:applicationCode/autopushes/:code/edit',
    redirect: '/applications/:applicationCode/push-notification/autopushes/:code/edit',
  },
  {
    pattern: '/v2/applications/:applicationCode/autopushes/add',
    redirect: '/applications/:applicationCode/push-notification/autopushes/add',
  },
  {
    pattern: '/v2/applications/:applicationCode/import-emails',
    redirect: '/applications/:applicationCode/import-csv/import-emails',
  },
  {
    pattern: '/v2/applications/:applicationCode/events',
    redirect: '/applications/:applicationCode/events',
  },
  {
    pattern: '/v2/applications/:applicationCode/events/:uid/edit',
    redirect: '/applications/:applicationCode/events/:uid/edit',
  },
  {
    pattern: '/v2/applications/:applicationCode/events/:uid/statistics',
    redirect: '/applications/:applicationCode/events/:uid/statistics',
  },
  {
    pattern: '/v2/applications/:applicationCode/events/:uid/add',
    redirect: '/applications/:applicationCode/events/:uid/add',
  },
  {
    pattern: '/v2/applications/:applicationCode/subscription-prompt',
    redirect: '/applications/:applicationCode/subscription-prompt',
  },
  // applications groups
  {
    pattern: '/v2/groups',
    redirect: '/projects/groups',
  },
  {
    pattern: '/v2/groups/:groupCode',
    redirect: '/groups/:groupCode',
  },
  {
    pattern: '/v2/groups/:groupCode/campaigns',
    redirect: '/groups/:groupCode/messaging-statistics/campaigns-statistic',
  },
  {
    pattern: '/v2/groups/:groupCode/campaigns/add',
    redirect: '/groups/:groupCode/messaging-statistics/campaigns-statistic',
  },
  {
    pattern: '/v2/groups/:groupCode/campaigns/:campaignCode/statistics',
    redirect: '/groups/:groupCode/messaging-statistics/campaigns-statistic/:campaignCode/statistics',
  },
  {
    pattern: '/v2/groups/:groupCode/campaigns/:campaignCode/edit',
    redirect: '/groups/:groupCode/messaging-statistics/campaigns-statistic',
  },
  {
    pattern: '/v2/groups/:groupCode/presets',
    redirect: '/groups/:groupCode/presets',
  },
  {
    pattern: '/v2/groups/:groupCode/deeplinks',
    redirect: '/groups/:groupCode/deeplinks',
  },
  {
    pattern: '/v2/groups/:groupCode/deeplinks/:deeplinkCode/edit',
    redirect: '/groups/:groupCode/deeplinks/:deeplinkCode/edit',
  },
  {
    pattern: '/v2/groups/:groupCode/deeplinks/add',
    redirect: '/groups/:groupCode/deeplinks/add',
  },
];
