export enum RouteTypes {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export type Route = {
  pattern: string;
  redirect: string;
};

export type MatchedRoute = Route & { type: RouteTypes; };
