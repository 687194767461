import styled, { createGlobalStyle } from 'styled-components';

import { Color } from '@pushwoosh/kit-constants';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.4;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
  }

  &:focus:not(:focus-visible) { outline: none }
`;

export const RootBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 24px;
  background-color: ${Color.CLEAR};
`;

export const ViewBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 40px;
  }
`;
