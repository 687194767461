import React from 'react';

import { useEnvironment } from '~/src/contexts/environment';
import { H1, RouterLink } from '~/src/ui-kit';

import { MessageCard } from '~/src/components/MessageCard';
import { StyledContentBox } from './SuccessPage.styles';

export function SuccessPage(): JSX.Element {
  const { getLinkByName } = useEnvironment();
  return (
    <StyledContentBox>
      <H1>Account Created</H1>
      <MessageCard>
        You have successfully registered.
        {' '}
        <RouterLink title="Sign in" to={getLinkByName('login')}>Sign in</RouterLink>
      </MessageCard>
    </StyledContentBox>
  );
}
