import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { getFetchOptions } from './useAPI.helper';

import {
  ApiPath,
  ApiRequestPayload,
  ApiResponsePayload,
  TUseAPI,
} from './useAPI.types';

export function useAPI(): TUseAPI {
  const navigate = useNavigate();

  const callAPI = useCallback(
    (
      path: ApiPath,
      payload: ApiRequestPayload,
      method?: 'POST' | 'GET',
    ): Promise<ApiResponsePayload> => {
      const query = method === 'GET' ? `?${new URLSearchParams(payload).toString()}` : '';

      return fetch(
        `/${path}${query}`,
        getFetchOptions(payload, method),
      )
        .then((response) => {
          if (response.redirected) {
            const { pathname, origin, href } = new URL(response.url);

            return {
              redirect: origin.includes('app.')
                ? (): void => { window.location.href = href; }
                : (): void => navigate(pathname),
            };
          }

          return response.json()
            .then((data) => (
              response.ok ? data : new Error(data.message)
            ))
            .catch(() => new Error(response.statusText));
        })
        .catch(({ message }) => new Error(message));
    },
    [navigate],
  );

  return { callAPI };
}
