import styled from 'styled-components';

export const ContentBox = styled.div`
  width: 100%;
  max-width: 450px;

  @media screen and (min-width: 480px) {
    border: 1px solid #dce1e6;
    border-radius: 16px;
    padding: 48px;
  }
`;
