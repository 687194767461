import React from 'react';

import { RecaptchaElement } from './Recaptcha.style';
import { RECAPTCHA_CONTAINER_ID } from './Recaptcha.constants';

export function ReCAPTCHA(): JSX.Element {
  return (
    <RecaptchaElement id={RECAPTCHA_CONTAINER_ID} />
  );
}
