import React, { useCallback, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { Spinner } from '@pushwoosh/kit-spinner';
import { ArrowIcon, PlatformLogoIcon, KeyIcon } from '@pushwoosh/kit-icons';
import { Link } from '@pushwoosh/kit-link';

import { useSession } from '~/src/contexts/sessionStore';
import { H1, H4 } from '~/src/ui-kit';
import { useEnvironment } from '~/src/contexts/environment';

import { ErrorCard } from '~/src/components/ErrorCard';
import { Backlinks } from '~/src/components/Backlinks';

import { Tag } from '@pushwoosh/kit-constants';
import { PostApiReponsePayload, useAPI } from '~/src/hooks/useAPI';

import { authOptionName } from '../helpers/authOptionName';

import {
  AccountBox, ListBox, StyledContentBox, AccountBoxName,
} from './AuthOptionsPage.styles';

export function AuthOptionsPage(): JSX.Element {
  const { callAPI } = useAPI();
  const { getLinkByName, productName } = useEnvironment();
  const session = useSession();
  const navigate = useNavigate();
  const { authOptions, confirmAccountID, authMethod } = session.state;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmiting] = useState(false);
  const isAllowSupport = productName === 'Pushwoosh';

  const selectAuthMethod = useCallback((
    uid: number,
    method: string,
  ): Promise<void> => {
    setErrorMessage('');
    setIsSubmiting(true);
    return callAPI('api/select-account', { account_id: `${uid}`, method: authMethod })
      .then((response) => {
        const { redirect, message } = response as PostApiReponsePayload;
        session.setAuthMethod(method);
        setIsSubmiting(false);
        // awaiting redirect and ok response will be errored as unexpected behavior
        return redirect ? redirect() : setErrorMessage(message);
      })
      .catch((error) => setErrorMessage(error.message));
  }, [callAPI, session, authMethod]);

  return (
    (confirmAccountID && authOptions)
      ? (
        <>
          <StyledContentBox>
            <H1>Authentication options</H1>
            <ListBox>
              {authOptions?.map((method) => (
                <AccountBox
                  key={method}
                  disabled={isSubmitting}
                  isSubmitting={!!isSubmitting}
                  onClick={(): void => {
                    selectAuthMethod(confirmAccountID, method);
                  }}
                >
                  <AccountBoxName>
                    { method === 'email'
                  && (<PlatformLogoIcon size="small" view="lined" type="email" />)}
                    { method === 'authenticator'
                  && (<KeyIcon size="small" view="lined" />)}
                    { method === 'whatsapp'
                  && (<KeyIcon size="small" view="lined" />)}
                    { method === 'sms'
                  && (<KeyIcon size="small" view="lined" />)}
                    <H4>{authOptionName(method)}</H4>
                  </AccountBoxName>
                  {
                    isSubmitting && (method === authMethod)
                      ? <Spinner size="small" />
                      : <ArrowIcon direction="right" size="small" />
                  }
                </AccountBox>
              ))}
            </ListBox>
            {!!errorMessage && <ErrorCard message={errorMessage} />}
            {isAllowSupport && (
              <Backlinks>
                <Link
                  title="Contact support"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://help.pushwoosh.com/hc/en-us/requests/new"
                >
                  Contact support
                </Link>
              </Backlinks>
            )}
          </StyledContentBox>
          <Backlinks>
            <Link
              as={Tag.BUTTON}
              title="Log out"
              onClick={(): void => navigate(getLinkByName('login'))}
            >
              Log out
            </Link>
          </Backlinks>
        </>
      )
      : <Navigate to="/select-account" />
  );
}
