import React, {
  useCallback,
  useEffect,
  useState,
  type ReactElement,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAPI, PostApiReponsePayload, SAMLApiResponsePayload } from '~/src/hooks/useAPI';
import { trackMixpanelEvent } from '~/src/helpers/mixpanel';
import { sendToGoogleTagManager } from '~/src/helpers/sendToGoogleTagManager';
import { useEnvironment } from '~/src/contexts/environment';

import { LoginPage } from './LoginPage.view';

import type { LoginSubmitData, ChekSSOData } from './LoginPage.types';

export function LoginPageWithSSO(): ReactElement {
  const { callAPI } = useAPI();
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [isSSOChecked, setIsSSOChecked] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [isLoadedInviteEmail, setIsLoadedInviteEmail] = useState(true);
  const { origins } = useEnvironment();
  const inviteCode = searchParams.get('invite') || '';

  const fetchData = useCallback(() => {
    if (inviteCode == null) {
      return;
    }

    const payload = new URLSearchParams();
    setIsLoadedInviteEmail(false);
    payload.append('code', inviteCode);

    fetch(
      `${origins.sso}/api/validate-invite`,
      {
        method: 'POST',
        body: payload,
      },
    )
      .then((res) => {
        if (res.status === 200) {
          res.json()
            .then(({ email }) => {
              setInviteEmail(email);
            });
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      })
      .finally(() => setIsLoadedInviteEmail(true));
  }, [origins, inviteCode]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const loginHandler = useCallback(({
    password,
    username,
    token,
  }: LoginSubmitData): Promise<void> => {
    setErrorMessage('');

    return callAPI(
      'api/login',
      {
        password,
        username,
        'g-recaptcha-response': token,
        ...(inviteCode && { invite: inviteCode }),
      },
    )
      .then((response) => {
        const { redirect, message } = response as PostApiReponsePayload;

        sendToGoogleTagManager({ event: 'login' });
        trackMixpanelEvent({ name: 'AutorizationCompleted' });

        // awaiting redirect and ok response will be errored as unexpected behavior
        return redirect ? redirect() : setErrorMessage(message);
      })
      .catch((error) => setErrorMessage(error.message));
  }, [callAPI, inviteCode]);

  const checkForSSOHandler = useCallback(({
    username,
    token,
  }: ChekSSOData): Promise<void> => {
    setErrorMessage('');

    return callAPI(
      'api/get-saml-endpoint',
      {
        username,
        'g-recaptcha-response': token,
      },
      'GET',
    )
      .then((response) => {
        const { ssoUrl } = response as SAMLApiResponsePayload;

        ssoUrl ? document.location.href = ssoUrl : setIsSSOChecked(true);
      })
      .catch((error) => setErrorMessage(error));
  }, [callAPI]);

  return (
    <LoginPage
      inviteCode={inviteCode}
      inviteEmail={inviteEmail}
      isLoadedInviteEmail={isLoadedInviteEmail}
      loginHandler={loginHandler}
      checkForSSOHandler={checkForSSOHandler}
      isSSOChecked={isSSOChecked}
      errorMessage={errorMessage}
    />
  );
}
