export const linksConfig = {
  login: '/login',
  selectAccount: '/select-account',
  logout: '/logout',
  register: '/register',
  resetPassword: '/reset-password',
  confirm: '/confirm-code',
  invite: '/invite',
  authOptions: '/auth-options',
};
