import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Link as KitLink, LinkPropsWithAsComponent } from '@pushwoosh/kit-link';

export function RouterLink(props: Omit<LinkPropsWithAsComponent<LinkProps>, 'as'>): JSX.Element {
  return (
    <KitLink {...props} as={Link} />
  );
}
