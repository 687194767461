import { ApiRequestPayload } from './useAPI.types';

export function getFetchOptions(body: ApiRequestPayload, method: string = 'POST'): RequestInit {
  const payload = new URLSearchParams();

  (Object.keys(body) as Array<keyof typeof body>).map((key) => payload.append(`${key}`, body[key] || ''));

  return {
    method,
    ...(method !== 'GET' && { body: payload }),
  };
}
