import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { TextInputField } from '@pushwoosh/kit-text-input';

import { useEnvironment } from '~/src/contexts/environment';
import {
  H1,
  FieldBox,
  Button,
  RouterLink,
} from '~/src/ui-kit';
import { Backlinks } from '~/src/components/Backlinks';
import { ContentBox } from '~/src/components/ContentBox';
import { ErrorCard } from '~/src/components/ErrorCard';
import { InfoNote } from '~/src/components/InfoNote';
import { MessageCard } from '~/src/components/MessageCard';

import {
  FormStyled,
  InputFieldsBox,
  MessageCardHeading,
  MessageCardNote,
} from './ChangePassword.styles';
import type { ChangePasswordProps } from './ChangePassword.types';
import { getErrorMessage } from './ChangePassword.helpers';

export function ChangePassword({ errorMessage, savePasswordHandler }: ChangePasswordProps): JSX.Element {
  const { getLinkByName, productName } = useEnvironment();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted },
    trigger,
    watch,
    getValues,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });
  const isAllowRegister = productName === 'Pushwoosh';

  useEffect(() => {
    const subscription = watch(() => isSubmitted && trigger());
    return () => subscription.unsubscribe();
  }, [watch, isSubmitted, trigger]);

  const validatePasswords = useCallback((): boolean => {
    const { password, confirmPassword } = getValues();
    return !!password && password === confirmPassword;
  }, [getValues]);

  return (
    <>
      <ContentBox>
        <FormStyled onSubmit={handleSubmit(savePasswordHandler)}>
          <H1>Change Password</H1>
          <MessageCard>
            <MessageCardHeading>Please use a secure password</MessageCardHeading>
            <MessageCardNote>Password must contains at least 8 characters</MessageCardNote>
          </MessageCard>
          <InputFieldsBox>
            <FieldBox
              title="New Password"
              isErrored={!!errors.password}
              helpText={errors.password && getErrorMessage(errors.password)}
            >
              <TextInputField
                $isErrored={!!errors.password}
                $withIcon={false}
                type="password"
                autoComplete="off"
                autoFocus
                {...register('password', { required: true, minLength: 8, validate: validatePasswords })}
              />
            </FieldBox>
            <FieldBox
              title="Confirm Password"
              isErrored={!!errors.confirmPassword}
              helpText={errors.confirmPassword && getErrorMessage(errors.confirmPassword)}
            >
              <TextInputField
                $isErrored={!!errors.confirmPassword}
                $withIcon={false}
                type="password"
                autoComplete="off"
                {...register('confirmPassword', { required: true, minLength: 8, validate: validatePasswords })}
              />
            </FieldBox>
            {!!errorMessage && !isSubmitting && <ErrorCard message={errorMessage} />}
          </InputFieldsBox>
          <Button
            type="submit"
            isLoading={isSubmitting}
            color="primary"
            width="100%"
          >
            Save Password
          </Button>
          <InfoNote>For security reasons all active sessions of the account will be signed out after password reset.</InfoNote>
        </FormStyled>
      </ContentBox>
      <Backlinks>
        <RouterLink title="Sign in" to={getLinkByName('login')}>Sign in</RouterLink>
        {' '}
        to another account
        {isAllowRegister && (
          <>
            {' '}
            or
            {' '}
            <RouterLink title="Sign up" to={getLinkByName('register')}>Sign up</RouterLink>
          </>
        )}
      </Backlinks>
    </>
  );
}
