import styled from 'styled-components';

import { Color, Spacing } from '@pushwoosh/kit-constants';

export const RootBox = styled.div`
  display: grid;
  grid-template-columns: none;
  align-items: flex-start;
  width: 100%;
  height: 100vh;

  @media (min-width: 980px) {
    grid-template-columns: fit-content(400px) 1fr;
  }
`;

export const SideBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: start;
  padding: ${Spacing.S6} ${Spacing.S16} ${Spacing.S11};
  background: ${Color.CLEAR};
  box-sizing: content-box;
  gap: ${Spacing.S6};
`;

export const SideContent = styled.div`
  display: flex;
  flex: 1;
  align-items: start;
  justify-content: center;

  @media (min-width: 980px) {
    align-items: center;
  }
`;

export const BackgroundBox = styled.div`
  display: none;
  position: relative;
  flex: 1 0 0;
  align-items: center;
  align-self: stretch;
  min-width: 920px;
  padding: ${Spacing.S16};
  background: linear-gradient(76deg, #006446 0%, ${Color.BRAND_BLACK} 100%);
  overflow: hidden;

  @media (min-width: 980px) {
    display: flex;
    min-width: auto;
  }

  &::before {
    content: '';
    position: absolute;
    width: 800px;
    height: 800px;
    left: 0;
    bottom: 0;
    border-radius: 50%;
    transform: translate(-50%, 50%);
    border: 200px solid rgba(0, 230, 160, 0.30);
    box-sizing: border-box;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 0 0;
  align-items: center;
  justify-content: center;
  min-width: 1000px;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 88px;
    height: 88px;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    background: rgba(0, 230, 160, 0.60);
  }
`;

export const ImageBox = styled.div`
  position: relative;
  width: 1000px;
  height: 570px;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    top: -130px;
    right: -130px;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: rgba(0, 230, 160, 0.40);
    z-index: -1;
  }
`;

export const ProductImage = styled.img`
  width: 1000px;
  height: 570px;
  border-radius: 16px;
`;

export const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 280px;
  max-width: 360px;
  color: ${Color.MAIN};
  gap: ${Spacing.S7};
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.S1};
`;

export const InputFieldsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.S5};
`;

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.S4};
`;

export const ButtonText = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing.S2};
`;

export const HorizontalLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing.S3};

  p {
    margin: 0;
    color: ${Color.LOCKED}
  }

  hr {
    width: 150px;
    border-width: 1px 0 0;
    border-color: ${Color.DIVIDER};
    border-style: solid;
  }
`;
