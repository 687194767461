export const getMinifiedSiteKey = (productName: string): string => (productName === 'OMO' ? '6LfjShcqAAAAALKCIUsun1S5HnLvBtXButLl_Je3' : '6LdS7DYUAAAAABVgt0djIBoyyMQ0oDAUl7jt1Mxh');

export async function asyncScriptLoad(scriptUrl: string): Promise<Event | void> {
  return new Promise((
    resolve,
    reject,
  ) => {
    const isAlreadyLoaded = document.querySelector(`script[src="${scriptUrl}"]`);
    if (isAlreadyLoaded) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = scriptUrl;
    script.async = true;

    document.body.appendChild(script);
    script.onload = resolve;
    script.onerror = reject;
  });
}
