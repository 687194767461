import React, { PropsWithChildren, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { ProductLogo } from './components/Logo';
import { SessionProvider } from './contexts/sessionStore';
import { EnvironmentProvider } from './contexts/environment';
import { ChangePasswordWithSSO } from './views/ChangePassword';
import { CreatePasswordWithSSO } from './views/CreatePassword';
import { ForgotPasswordWithSSO } from './views/ForgotPassword';
import { LoginPageWithSSO } from './views/LoginPage';
import { RegisterPageWithSSO } from './views/Register';
import { SelectAccountWithSSO } from './views/SelectAccount';
import { InvitePageWithSSO } from './views/InvitePage';
import { TwoFactorCodeWithSSO } from './views/TwoFactorCode';
import { ExternalRedirect } from './views/RedirectV2';
import { AuthOptionsPage } from './views/AuthOptionsPage';

import {
  GlobalStyles,
  RootBox,
  ViewBox,
} from './app.styles';
import { useUserMetricsCollector } from './helpers/sendStatisticsOnUserActions';

if (process.env['NODE_ENV'] === 'production') {
  Sentry.init({
    dsn: process.env['SENTRY_DSN'],
    release: process.env['VERSION'],
  });
}

function UserMetricsProvider({ children }: PropsWithChildren): JSX.Element {
  const userMetricsCollector = useUserMetricsCollector();

  useEffect(() => {
    userMetricsCollector.subscribe();

    return () => {
      userMetricsCollector.unsubscribe();
    };
  }, [userMetricsCollector]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export function App(): JSX.Element {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <EnvironmentProvider applicationName="sso">
        <SessionProvider>
          <UserMetricsProvider>
            <RootBox>
              <ViewBox>
                <ProductLogo />
                <Routes>
                  <Route path="/" element={<Navigate to="/login" />} />
                  <Route path="/login" element={<LoginPageWithSSO />} />
                  <Route path="/select-account" element={<SelectAccountWithSSO />} />
                  <Route path="/confirmation-code" element={<TwoFactorCodeWithSSO />} />
                  <Route path="/auth-options" element={<AuthOptionsPage />} />
                  <Route path="/reset-password" element={<ForgotPasswordWithSSO />} />
                  <Route path="/reset-password/:token" element={<ChangePasswordWithSSO />} />
                  <Route path="/register" element={<RegisterPageWithSSO />} />
                  <Route path="/create-password/:token/:email/:account" element={<CreatePasswordWithSSO />} />
                  <Route path="/invite/:token" element={<InvitePageWithSSO />} />
                  <Route path="/authorize" element={<ExternalRedirect to="/" />} />
                </Routes>
              </ViewBox>
            </RootBox>
          </UserMetricsProvider>
        </SessionProvider>
      </EnvironmentProvider>
    </BrowserRouter>
  );
}
