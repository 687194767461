import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { TextInputField } from '@pushwoosh/kit-text-input';
import { Checkbox } from '@pushwoosh/kit-checkbox';

import {
  H1,
  FieldBox,
  Button,
} from '~/src/ui-kit';
import { ContentBox } from '~/src/components/ContentBox';
import { ErrorCard } from '~/src/components/ErrorCard';
import { MessageCard } from '~/src/components/MessageCard';

import {
  FormStyled,
  InputFieldsBox,
  MessageCardHeading,
  MessageCardNote,
} from './CreatePassword.styles';
import type { CreatePasswordProps } from './CreatePassword.types';
import { getErrorMessage } from './CreatePassword.helpers';

export function CreatePassword({ errorMessage, createPasswordHandler }: CreatePasswordProps): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted },
    trigger,
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      password: '',
      confirmPassword: '',
      allowMarketing: false,
    },
  });

  useEffect(() => {
    const subscription = watch(() => isSubmitted && trigger());
    return () => subscription.unsubscribe();
  }, [watch, isSubmitted, trigger]);

  const validatePasswords = useCallback((): boolean => {
    const { password, confirmPassword } = getValues();
    return !!password && password === confirmPassword;
  }, [getValues]);

  const isAllowMarketing = watch('allowMarketing');

  return (
    <ContentBox>
      <FormStyled onSubmit={handleSubmit(createPasswordHandler)}>
        <H1>Create a Password</H1>
        <MessageCard>
          <MessageCardHeading>Please use a secure password</MessageCardHeading>
          <MessageCardNote>Password must contains at least 8 characters</MessageCardNote>
        </MessageCard>
        <InputFieldsBox>
          <FieldBox
            title="Password"
            isErrored={!!errors.password}
            helpText={errors.password && getErrorMessage(errors.password)}
          >
            <TextInputField
              $isErrored={!!errors.password}
              $withIcon={false}
              type="password"
              autoComplete="off"
              autoFocus
              {...register('password', { required: true, minLength: 8, validate: validatePasswords })}
            />
          </FieldBox>
          <FieldBox
            title="Repeat Password"
            isErrored={!!errors.confirmPassword}
            helpText={errors.confirmPassword && getErrorMessage(errors.confirmPassword)}
          >
            <TextInputField
              $isErrored={!!errors.confirmPassword}
              $withIcon={false}
              type="password"
              autoComplete="off"
              {...register('confirmPassword', { required: true, minLength: 8, validate: validatePasswords })}
            />
          </FieldBox>
          {!!errorMessage && !isSubmitting && <ErrorCard message={errorMessage} />}
        </InputFieldsBox>
        <Button
          type="submit"
          isLoading={isSubmitting}
          color="primary"
          width="100%"
        >
          Complete Registration
        </Button>
        <Checkbox
          name="allowMarketing"
          isChecked={watch('allowMarketing')}
          value
          onChange={(): void => setValue('allowMarketing', !isAllowMarketing)}
        >
          I would like to receive service updates, news and announcements
        </Checkbox>
      </FormStyled>
    </ContentBox>
  );
}
