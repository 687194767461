import React, { type ReactElement } from 'react';
import type { PropsWithChildren } from 'react';

import { InfoRoundIcon } from '@pushwoosh/kit-icons';

import {
  RootBox,
  IconBox,
  MessageBox,
} from './MessageCard.styles';

export function MessageCard({ children }: PropsWithChildren): ReactElement {
  return (
    <RootBox>
      <IconBox>
        <InfoRoundIcon size="medium" view="filled" />
      </IconBox>
      <MessageBox>{children}</MessageBox>
    </RootBox>
  );
}
