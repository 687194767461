export const SAML_CONFIGS = [
  {
    host: 'nestle.pushwoosh.com',
    url: '/loginSaml',
  },
  {
    host: 'euroconsumers.pushwoosh.com',
    url: '/loginSaml/euroconsumers',
  },
  {
    host: 'lamoda.pushwoosh.com',
    url: '/loginSaml/lamoda',
  },
  {
    host: 'boskalis.pushwoosh.com',
    url: '/loginSaml/boskalis',
  },
];
