import styled from 'styled-components';

import {
  Color,
  FontSize,
  LineHeight,
  FontWeight,
} from '@pushwoosh/kit-constants';

export const InfoNote = styled.div<{ align?: string }>`
  color: ${Color.LOCKED};
  font-weight: ${FontWeight.REGULAR};
  font-size: ${FontSize.REGULAR};
  line-height: ${LineHeight.REGULAR};
  text-align: ${({ align }): string => align || 'left'};
`;
