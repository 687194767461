import React, { type ReactElement } from 'react';

import { Color } from '@pushwoosh/kit-constants';
import { Text } from '@pushwoosh/kit-typography';

import { HorizontalLine } from './Separator.styles';

export function Separator(): ReactElement {
  return (
    <HorizontalLine>
      <hr />
      <Text $color={Color.LOCKED} $uppercase>OR</Text>
      <hr />
    </HorizontalLine>
  );
}
