import styled from 'styled-components';

import { Checkbox as KitCheckbox } from '@pushwoosh/kit-checkbox';

import { focusMixin } from './mixins.styles';

export const Checkbox = styled(KitCheckbox)`
  div[class^='CheckboxText'] {
    line-height: 20px;
  }

  input[type='checkbox'] {
    ${focusMixin}
  }
`;
