import React from 'react';

import {
  StyledContainer,
  StyledSkeleton,
  StyledRadioButton,
  StyledTitle,
} from './InviteOptionsSkeleton.styles';

export function InviteOptionsSkeleton(): JSX.Element {
  return (
    <StyledContainer>
      {Array.from({ length: 3 }, (_, i) => i + 1).map((option) => (
        <StyledSkeleton key={`skeleton_${option}`}>
          <StyledRadioButton />
          <StyledTitle />
        </StyledSkeleton>
      ))}
    </StyledContainer>
  );
}
