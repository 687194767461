export function getCookie(name: string, defaultValue: string | null = null): string | null {
  if (document.cookie) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i] || '';
      const [key, value] = cookie.split('=');
      if (key && key.trim() === name && typeof value === 'string') {
        return value;
      }
    }
  }
  return defaultValue;
}

export function setCookie(key: string, value: string, hoursExpire: number, path = '/', domain = document.location.hostname): void {
  const date = new Date();
  const expireTime = date.getTime() + (hoursExpire * 60 * 60 * 1000);
  date.setTime(expireTime);
  document.cookie = `${key}=${value};expires=${date.toUTCString()};domain=${domain};path=${path};`;
}
