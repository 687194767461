import styled from 'styled-components';

import { Color, FontWeight, ShapeRadius } from '@pushwoosh/kit-constants';

export const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 5px 6px;
  font-weight: ${FontWeight.MEDIUM};
  color: ${Color.LOCKED};
  background: ${Color.FROZEN};
  border-radius: ${ShapeRadius.CONTROL};
  border: 1px solid ${Color.FORM};
  gap: 4px;
`;
