const authOptionsArray = [
  {
    method: 'email',
    name: 'Email',
  },
  {
    method: 'authenticator',
    name: 'Authentication app',
  },
  {
    method: 'sms',
    name: 'SMS',
    logo: '',
  },
  {
    method: 'whatsapp',
    name: 'WhatsApp',
    logo: '',
  },
];

export const authOptionName = (optionName: string): string | undefined => authOptionsArray.find((option) => option.method === optionName)?.name;
