import React, {
  useState,
  useEffect,
  useCallback,
  type ReactElement,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  CountryCode,
  checkIsAvailableCountyCode,
  getCountryCodeByIp,
  getCountryName,
} from '@pushwoosh/geo-ip';
import { useAPI } from '~/src/hooks/useAPI';
import { sendToGoogleTagManager } from '~/src/helpers/sendToGoogleTagManager';
import { identifyMixpanel, trackMixpanelEvent } from '~/src/helpers/mixpanel';
import { getCookie } from '~/src/helpers/cookies';
import { useEnvironment } from '~/src/contexts/environment';

import { RegisterPage } from './Register.view';
import { SuccessPage } from './components/SuccessPage';

import type { RegisterPageFormValues } from './Register.types';

export function RegisterPageWithSSO(): ReactElement {
  const [countryCode, setCountryCode] = useState<CountryCode | null>(null);
  const [isLoadedCountry, setIsLoadedCountry] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successResult, setSuccessResult] = useState({} as { email: string });
  const [inviteEmail, setInviteEmail] = useState('');
  const [isLoadedInviteEmail, setIsLoadedInviteEmail] = useState(true);
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('invite') || '';
  const { callAPI } = useAPI();
  const { origins } = useEnvironment();

  const fetchData = useCallback(() => {
    const payload = new URLSearchParams();
    if (inviteCode == null) {
      return;
    }
    setIsLoadedInviteEmail(false);
    payload.append('code', inviteCode);

    fetch(
      `${origins.sso}/api/validate-invite`,
      {
        method: 'POST',
        body: payload,
      },
    )
      .then((res) => {
        if (res.status === 200) {
          res.json()
            .then(({ email }) => {
              setInviteEmail(email);
            });
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      })
      .finally(() => setIsLoadedInviteEmail(true));
  }, [origins, inviteCode]);

  useEffect(() => {
    fetchData();
    getCountryCodeByIp()
      .then((code) => {
        if (checkIsAvailableCountyCode(code)) {
          setCountryCode(code);
        }
      })
      .finally(() => {
        setIsLoadedCountry(true);
      });
  }, [fetchData]);

  const registerHandler = useCallback(({
    token,
    email,
    firstName,
    lastName,
    phone,
  }: RegisterPageFormValues) => {
    const referralHash = getCookie('__PW_REFERRAL_PROGRAM_CODE__');

    trackMixpanelEvent({ name: 'SignUpFormCompleted' });

    return fetch(
      `${origins.userApi}/register`,
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          country: countryCode ? getCountryName(countryCode) : '',
          countryCode,
          email,
          phone,
          recaptchaResponse: token,
          ...referralHash && { referral_hash: referralHash },
          ...inviteCode && { invite: inviteCode },
        }),
      },
    )
      .then(async (res) => {
        const { message } = await res.json();

        if (res.status === 200) {
          identifyMixpanel(email ?? '');
          sendToGoogleTagManager({ event: 'sign_up' });
          trackMixpanelEvent({
            name: 'AccountRegistered',
            properties: {
              Date: new Date(),
            },
          });
          await callAPI('api/register', {});
          setSuccessResult({ email });
        } else {
          throw new Error(message);
        }
      })
      .catch((error) => setErrorMessage(error.message));
  }, [inviteCode, origins, countryCode, callAPI]);

  return (
    successResult.email
      ? <SuccessPage email={successResult.email} />
      : (
        <RegisterPage
          inviteCode={inviteCode}
          inviteEmail={inviteEmail}
          isLoadedInviteEmail={isLoadedInviteEmail}
          isLoadedCountry={isLoadedCountry}
          errorMessage={errorMessage}
          registerHandler={registerHandler}
        />
      )
  );
}
