import styled from 'styled-components';

import {
  H1 as KitH1,
  H3 as KitH3,
  H4 as KitH4,
  Paragraph as KitParagraph,
} from '@pushwoosh/kit-typography';
import {
  Color,
  FontSize,
  FontWeight,
  LineHeight,
} from '@pushwoosh/kit-constants';

export const H1 = styled(KitH1)`
  color: ${Color.MAIN};
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 22px;
  font-weight: ${FontWeight.BOLD};
  line-height: ${LineHeight.HEADING1};
`;

export const H3 = styled(KitH3)`
  color: ${Color.MAIN};
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: ${FontSize.HEADING3};
  font-weight: ${FontWeight.BOLD};
  line-height: ${LineHeight.HEADING3};
`;

export const H4 = styled(KitH4)`
  color: ${Color.MAIN};
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: ${FontSize.HEADING4};
  font-weight: ${FontWeight.MEDIUM};
  line-height: ${LineHeight.HEADING4};
`;

export const Paragraph = styled(KitParagraph)`
  color: ${Color.MAIN};
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.REGULAR};
  line-height: ${LineHeight.REGULAR};
`;
