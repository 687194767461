import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.2;
  }
`;

export const StyledAccountBox = styled.div`
  width: 100%;
  max-width: 354px;
  border: 1px solid #DCE1E6;
  border-radius: 4px;
  padding: 16px 18px;
  display: flex;
  justify-content: space-between;
`;

export const StyledTitle = styled.div<{ width: string }>`
  width: ${(props): string => props.width || 'auto'};
  height: 16px;
  padding: 2px 0;
  background: grey;
  border-radius: 8px;
  opacity: 0.1;
  animation: ${animation} 1s ease-in-out infinite;
`;

export const StyledIcon = styled.div`
  width: 16px;
  height: 16px;
  padding: 4px 0;
  background: grey;
  border-radius: 8px;
  opacity: 0.1;
  animation: ${animation} 1s ease-in-out infinite;
`;
