import React from 'react';

import { CloseRoundIcon } from '@pushwoosh/kit-icons';

import {
  RootBox,
  IconBox,
  MessageBox,
} from './ErrorCard.styles';

import { ErrorCardProps } from './ErrorCard.types';

export function ErrorCard({ message }: ErrorCardProps): JSX.Element {
  return (
    <RootBox>
      <IconBox>
        <CloseRoundIcon size="medium" view="filled" />
      </IconBox>
      <MessageBox>{message}</MessageBox>
    </RootBox>
  );
}
