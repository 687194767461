import styled from 'styled-components';

import { FieldBox as KitFiledBox } from '@pushwoosh/kit-field-box';

export const FieldBox = styled(KitFiledBox)`
  > div[class^='FieldBoxHeader'] {
    min-height: 16px;
    font-family: 'DM Sans';
    font-size: 12px;
    line-height: 1.3;
    text-transform: uppercase;
  }
`;
