export enum SessionActionTypes {
  SET_CONFIRM_ACCOUNT = 'SET_CONFIRM_ACCOUNT',
  SET_IS_ADMIN = 'SET_IS_ADMIN',
  SET_AUTH_OPTIONS = 'SET_AUTH_OPTIONS',
  SET_AUTH_METHOD = 'SET_AUTH_METHOD',
}

export type SessionState = {
  readonly confirmAccountID?: number;
  readonly isAdmin?: boolean;
  readonly authOptions?: string[],
  readonly authMethod?: string,
};

export type TSessionStore = {
  state: SessionState;
  setConfirmationAccount: (accountID: number) => void;
  setIsAdmin: () => void;
  setAuthOptions: (methods: string[]) => void;
  setAuthMethod: (method: string) => void;
};

export type SessionAction = {
  type: SessionActionTypes;
  payload: any;
};
