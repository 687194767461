import React, {
  useState,
  useMemo,
  useCallback,
  type ReactElement,
} from 'react';
import Select from 'react-select';

import { Button } from '~/src/ui-kit';
import { ErrorCard } from '~/src/components/ErrorCard';
import { AccountsSkeleton } from '../AccountsSkeleton';
import { ListBox } from './AccountsSelect.styles';

import { SelectComponentProps } from '../../SelectAccount.types';
import { SelectAccountOption } from './AccountsSelect.types';

export function AccountsSelect(props: SelectComponentProps): ReactElement {
  const {
    accountsList,
    errorMessage,
    isLoading,
    isSubmitting,
    onAccountClick,
  } = props;

  const [selectedOption, setSelectedOption] = useState<SelectAccountOption | null>(null);

  const onSubmit = useCallback((): void => {
    if (selectedOption) {
      onAccountClick(selectedOption.value, selectedOption.methods, selectedOption.method);
    }
  }, [selectedOption, onAccountClick]);

  const defaultValue = useMemo(() => {
    if (accountsList[0]) {
      const initialOption = {
        label: accountsList[0].name,
        value: accountsList[0].uid,
        isDisabled: !accountsList[0].allowSelect,
        isAdmin: false,
        methods: accountsList[0].methods,
        method: accountsList[0].method,
      };
      setSelectedOption(initialOption);
      return initialOption;
    }

    return null;
  }, [accountsList]);

  const accountOptions = accountsList.map(({
    uid,
    name,
    allowSelect,
    methods,
    method,
  }) => ({
    label: name,
    value: uid,
    isDisabled: !allowSelect,
    isAdmin: false,
    methods,
    method,
  }));

  return (
    <>
      <ListBox>
        {isLoading && <AccountsSkeleton />}
        <Select
          isLoading={isLoading}
          defaultValue={defaultValue}
          options={accountOptions}
          onChange={(option): void => setSelectedOption(option as SelectAccountOption)}
        />
      </ListBox>
      {!!errorMessage && <ErrorCard message={errorMessage} />}
      {(!isLoading) && (
        <Button
          width="100%"
          type="submit"
          color="primary"
          isLoading={isSubmitting}
          isDisabled={Boolean(!selectedOption)}
          onClick={onSubmit}
        >
          Continue
        </Button>
      )}
    </>
  );
}
