import styled from 'styled-components';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 24px;
  }
`;

export const StyledDescription = styled.span`
  margin-top: 4px;
  color: #4b5057;
`;

export const RadioGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
`;
