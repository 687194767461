import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import type { PropsWithChildren } from 'react';

import type {
  TEnvironment,
  LinkName,
  ApiDomain,
} from './environment.types';

import {
  linksConfig,
} from './environment.constants';

const EnvironmentContext = createContext<TEnvironment | null>(null);

export function EnvironmentProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const productName = useMemo(
    (): string => {
      const { origin } = window.location;

      if (origin.includes('omo-tech')) {
        return 'OMO';
      } if (origin.includes('wavesend')) {
        return 'Wavesend';
      }
      return 'Pushwoosh';
    },
    [],
  );

  const getApiOrigin = useCallback((apiDomain?: ApiDomain) => {
    const { origin } = window.location;

    if (!apiDomain || origin.includes('localhost')) {
      return 'https://sso.pushwoosh.com';
    }

    if (origin.includes('sso.omo-tech') || origin.includes('sso.pushwoosh')) {
      return `https://${apiDomain}.svc-nue.pushwoosh.com`;
    }

    if (origin.includes('sso.wavesend')) {
      return origin.replace('sso.', `${apiDomain}.svc.`);
    }

    return origin.replace('sso.', `${apiDomain}.`);
  }, []);

  const getLinkByName = useCallback(
    (linkName: LinkName) => linksConfig[linkName],
    [],
  );

  const redirectToApp = useCallback((path = '') => {
    window.location.href = `${window.location.origin.replace('sso', 'app')}${path}`;
  }, []);

  const ProviderValues = useMemo(() => ({
    getLinkByName,
    redirectToApp,
    getApiOrigin,
    productName,
  }), [getLinkByName, redirectToApp, getApiOrigin, productName]);

  return (
    <EnvironmentContext.Provider value={ProviderValues}>
      {children}
    </EnvironmentContext.Provider>
  );
}

export function useEnvironment(): TEnvironment {
  const environment = useContext(EnvironmentContext);
  if (!environment) {
    throw new Error('Environment is not defined! Please check what are you using useSession in a child of EnvironmentProvider!');
  }

  return environment;
}
