import { FieldError } from 'react-hook-form';

export function getErrorMessage(error: FieldError): string {
  if (error.type === 'required') {
    return 'Please specify password';
  }

  if (error.type === 'minLength') {
    return 'Password must contains at least 8 characters';
  }

  return 'Passwords do not match';
}
