import mixpanel from 'mixpanel-browser';

type Event = {
  readonly name: string;
  readonly properties?: Record<string, unknown>;
};

const isProductionMode = process.env['NODE_ENV'] === 'production';

export function initMixpanelSession(): void {
  if (!isProductionMode) {
    return;
  }

  mixpanel.init('4169a4e38679ed49785b430a1049238e', {
    debug: true,
  });
}

export function identifyMixpanel(email: string): void {
  if (isProductionMode && mixpanel) {
    mixpanel.identify(email);
    mixpanel.people.set({ $email: email });
  }
}

export function trackMixpanelEvent(event: Event): void {
  if (!isProductionMode) {
    return;
  }

  mixpanel.track(event.name, event.properties);
}
