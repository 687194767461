import styled from 'styled-components';

import { Color } from '@pushwoosh/kit-constants';

export const HorizontalLine = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  hr {
    width: 150px;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: ${Color.DIVIDER};
  }
`;
