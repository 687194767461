import styled from 'styled-components';

import { focusMixin } from '../mixins.styles';

export const StyledRadioContainer = styled.div.attrs({ tabIndex: 0 })`
  display: flex;
  align-items: center;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  ${focusMixin}
`;

export const StyledRadioInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  opacity: 0;
`;

export const StyledLabel = styled.label<{ checked: boolean }>`
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 0 26px;
  cursor: pointer;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: ${({ checked }): string => (checked ? '6px solid #0e72e5' : '2px solid #c4c8cc')};
    border-radius: 14px;
    transition: border-color 0.28s cubic-bezier(.4, 0, .2, 1);
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    transform: ${({ checked }): string => (checked ? 'scale(1, 1)' : 'scale(0, 0)')};
    transition: transform 0.28s cubic-bezier(.4, 0, .2, 1);
    z-index: 2;
  }
`;
