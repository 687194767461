import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.2;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSkeleton = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: 12px;
  }
`;

export const StyledRadioButton = styled.div`
  width: 16px;
  height: 16px;
  background: grey;
  border-radius: 50%;
  margin-right: 6px;
  opacity: 0.1;
  animation: ${animation} 1s ease-in-out infinite;
`;

export const StyledTitle = styled.div`
  width: 100%;
  height: 16px;
  padding: 2px 0;
  background: grey;
  border-radius: 8px;
  opacity: 0.1;
  animation: ${animation} 1s ease-in-out infinite;
`;
