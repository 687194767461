import styled from 'styled-components';

export const HeaderBox = styled.div`
  > * + * {
    margin-top: 4px;
  }
`;

export const ContentRows = styled.div`
  > * + * {
    margin-top: 32px;
  }
`;
