import React from 'react';
import styled from 'styled-components';

import { Button as KitButton } from '@pushwoosh/kit-button';

import { focusMixin } from './mixins.styles';

function getFocusColor(color: string, view: string): string {
  if (color !== 'secondary') {
    if (view === 'shape') {
      return '#FFF';
    }
    return color === 'danger' ? '#CC2149' : '#0C66CC';
  }

  return '#0C66CC';
}

const StyledButton = styled.div<{ color: string }>`
  div[class^='ButtonText'] {
    font-family: 'DM Sans';
    font-size: 14px;
    line-height: 1.4;
  }

  button {
    ${focusMixin}
  }

  a:focus {
    color: ${(props): string => props.color};
  }
`;

// Can not use styled components here because they override 'as' attribute
// It is unreal to type this block because button component uses overloads
export function Button(props: any): JSX.Element {
  const { width = '', color = 'primary', view = 'shape' } = props;
  return (
    <StyledButton color={getFocusColor(color, view)}>
      <KitButton
        style={{
          width: width || 'auto',
        }}
        {...props}
      />
    </StyledButton>
  );
}
