import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ForgotPassword } from './ForgotPassword.view';

import type { ForgotPasswordFormValues } from './ForgotPassword.types';

export function ForgotPasswordWithSSO(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [resultMessage, setResultMessage] = useState('');

  const sendTokenHandler = useCallback(
    ({ resetEmail }: ForgotPasswordFormValues) => fetch(
      '/api/v1/accounts/forgotPassword',
      {
        method: 'POST',
        body: JSON.stringify({ email: resetEmail }),
      },
    )
      .then(async (r) => {
        const { message } = await r.json() as { message: string };

        if (r.status === 200) {
          setResultMessage(message);
        } else {
          throw new Error(message);
        }
      })
      .catch((error) => setErrorMessage(error.message)),
    [],
  );

  return (
    <ForgotPassword
      errorMessage={errorMessage}
      initialEmail={searchParams.get('email') || ''}
      sendTokenHandler={sendTokenHandler}
      resultMessage={resultMessage}
    />
  );
}
