import styled from 'styled-components';

export const ListBox = styled.div`
  > * + * {
    margin-top: 8px;
  }
`;

export const AccountBox = styled.button<{ isSubmitting?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 354px;
  border-width: 1px;
  border-style: solid;
  border-color: #DCE1E6;
  border-radius: 4px;
  background-color: #fff;
  padding: 16px 18px;
  pointer-events: ${({ isSubmitting }): string => (isSubmitting ? 'none' : 'auto')};
  cursor: pointer;

  &:hover:enabled {
    color: #1A85FF;
    background: rgba(153, 201, 255, 0.14);
    border-color: rgba(128, 183, 255, 0.2);
  }

  &:focus:enabled {
    color: #1A85FF;
    background: rgba(153, 201, 255, 0.14);
    border-color: rgba(128, 183, 255, 0.2);
  }

  &:active:enabled {
    background: rgba(153, 201, 255, 0.14);
    opacity: 0.5;
    border-color: rgba(128, 183, 255, 0.2);
  }

  &:disabled {
    background: #F7F9FA;
    border-color: #DCE1E6;
  }
`;
