import { useState, useEffect } from 'react';
import {
  TUseCountdown,
} from './useCountdown.types';

export function useCountdown(delay: number): TUseCountdown {
  const [waitSeconds, setWaitSeconds] = useState(delay);
  const [countStartDate, setCountStartDate] = useState(Date.now());

  useEffect(() => {
    const intervalID = setInterval(() => {
      const diff = Math.floor((Date.now() - countStartDate) / 1000);
      setWaitSeconds(delay - diff);
    }, 1000);

    const timeoutID = setTimeout(() => {
      clearInterval(intervalID);
      setWaitSeconds(0);
    }, delay * 1000);

    return () => {
      clearInterval(intervalID);
      clearTimeout(timeoutID);
    };
  }, [countStartDate, delay]);

  return { waitSeconds, setCountStartDate };
}
