import React, { PropsWithChildren } from 'react';

import { Tooltip } from '@pushwoosh/kit-tooltip';

export function AccountTooltip({ isAllowSelect, children }: PropsWithChildren<{ isAllowSelect: boolean }>): JSX.Element {
  return (
    !isAllowSelect ? (
      <Tooltip
        position="top-middle"
        content="Multiuser login is not available on this account according to its current subscription plan."
        maxWidth={320}
      >
        <div>{children}</div>
      </Tooltip>
    ) : <div>{children}</div>
  );
}
