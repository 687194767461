import { SessionActionTypes } from './sessionStore.types';
import type { SessionState, SessionAction } from './sessionStore.types';

export const reducer = (state: SessionState, action: SessionAction): SessionState => {
  switch (action.type) {
    case SessionActionTypes.SET_CONFIRM_ACCOUNT:
      return {
        ...state,
        confirmAccountID: action.payload,
      };
    case SessionActionTypes.SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: true,
      };
    case SessionActionTypes.SET_AUTH_OPTIONS:
      return {
        ...state,
        authOptions: action.payload,
      };
    case SessionActionTypes.SET_AUTH_METHOD:
      return {
        ...state,
        authMethod: action.payload,
      };
    default:
      return state;
  }
};

export const initialState = {};
