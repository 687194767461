import mixpanel from 'mixpanel-browser';
import { getCookie } from './cookies';

type Event = {
  readonly name: string;
  readonly properties?: Record<string, unknown>;
};

const isProductionMode = process.env['NODE_ENV'] === 'production';
const projectToken = '4169a4e38679ed49785b430a1049238e';

export function initMixpanelSession(): void {
  if (!isProductionMode) {
    return;
  }

  mixpanel.init(projectToken, {
    debug: true,
  });
}

export function identifyMixpanel(email: string): void {
  if (isProductionMode && mixpanel) {
    mixpanel.identify(email);
    mixpanel.people.set({ $email: email });
  }
}

export function trackMixpanelEvent(event: Event): void {
  if (!isProductionMode) {
    return;
  }

  mixpanel.track(event.name, event.properties);
}

export function getMixpanelDistinctId(): string | undefined {
  const mixpanelCookie = getCookie(`mp_${projectToken}_mixpanel`);
  if (mixpanelCookie) {
    try {
      const mixpanelData = JSON.parse(decodeURIComponent(mixpanelCookie));
      return mixpanelData.distinct_id;
    } catch (error) {
      console.error('Error parsing Mixpanel cookie:', error);
    }
  }
  return undefined;
}
