import React from 'react';

import { useEnvironment } from '~/src/contexts/environment';
import { PWLogo } from './PWLogo';
import { OmoLogo } from './OmoLogo';

export function ProductLogo(): JSX.Element {
  const { productName } = useEnvironment();

  return productName === 'OMO' ? <OmoLogo width={80} height={80} /> : <PWLogo width={80} height={80} />;
}
