import {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';

import { getMinifiedSiteKey, asyncScriptLoad } from './Recaptcha.helpers';
import { RECAPTCHA_SCRIPT_URL, RECAPTCHA_CONTAINER_ID } from './Recaptcha.constants';
import { RecaptchaExposedApi } from './Recaptcha.types';

// Everybody hate recaptcha
// The library does not have propper event based initialization and have idiotic callback process to get token on synchronous submit.
// We will invert all API and will do some magic shit here to get best developer expirience closer.

// Hook workflow:
// 1. firstload -> loadscript -> setinterval -> update state with intervalID
// 2. interval ticks -> looking for initialized library object -> if yes - update state with recaptchaLoaded
// 3. render recaptcha widget -> update state with recaptcha widgetID

// How to submit:
// getRecapthaToken is exposed async function, that returns token string.
// We use ref object here to store resolve method of promisse and wait for recaptcha callbacks.
export function useRecaptcha(productName: string): RecaptchaExposedApi {
  const [isRecaptchaLoaded, setRecaptchaLoaded] = useState(!!(window as any)?.grecaptcha?.render);
  const [widgetID, setWidgetID] = useState(null);
  const [isRecaptchaReady, setRecaptchaReady] = useState(!!(isRecaptchaLoaded && widgetID));
  const [recaptchaErrorCode, setRecaptchaErrorCode] = useState(''); // RIEXXX - Recaptcha Internal Error
  const [intervalID, setIntervalID] = useState(0);
  const promiseRef: any = useRef();

  // Load external script effect, fires once
  useEffect(() => {
    asyncScriptLoad(RECAPTCHA_SCRIPT_URL)
      .catch(() => {
        setRecaptchaErrorCode('RIE001');
      });
  }, []);

  // Set interval while library download and initialize
  useEffect(() => {
    const newInterval = (
      window.setInterval(() => {
        if ((window as any)?.grecaptcha?.render) {
          setRecaptchaLoaded(true);
        }
      }, 500));

    setIntervalID(newInterval);

    return (): void => {
      clearInterval(newInterval);
    };
  }, []);

  // render recaptcha widgte when script loaded, stop interval
  useEffect(() => {
    if (isRecaptchaLoaded && widgetID === null) {
      clearInterval(intervalID);

      try {
        const widget = (window as any).grecaptcha.render(RECAPTCHA_CONTAINER_ID, {
          sitekey: getMinifiedSiteKey(productName),
          size: 'invisible',
          callback: (token: string) => promiseRef.resolve(token),
          // 'expired-callback': () => {},
          // 'error-callback': () => {},
        });

        setWidgetID(widget);
        setRecaptchaReady(true);
      } catch (error) {
        setRecaptchaErrorCode('RIE002');
      }
    }
  }, [isRecaptchaLoaded, widgetID, intervalID, productName]);

  // async function to get recaptcha token, resolves by resolveAction callback
  const getRecaptchaToken = useCallback(async (): Promise<string> => (
    new Promise((resolve, reject) => {
      try {
        (window as any).grecaptcha.reset(widgetID);
        (window as any).grecaptcha.execute(widgetID);
      } catch (error) {
        reject(error);
      }

      promiseRef.resolve = resolve;
    })), [widgetID, promiseRef]);

  return {
    isRecaptchaReady,
    recaptchaErrorCode,
    getRecaptchaToken,
  };
}
