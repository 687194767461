import React, { useCallback, useState } from 'react';
import { Link } from '@pushwoosh/kit-link';

import { H1 } from '~/src/ui-kit';
import { useEnvironment } from '~/src/contexts/environment';
import { Backlinks } from '~/src/components/Backlinks';

import { AccountsList } from './components/AccountsList';
import { AccountsSelect } from './components/AccountsSelect';

import { StyledContentBox } from './SelectAccount.styles';

import type { SelectAccountProps } from './SelectAccount.types';

const ACCOUNTS_COUNT_FOR_SELECT_VIEW = 4;

export function SelectAccount({
  accountsList,
  errorMessage,
  isLoading,
  isShowAdminPanel,
  selectHandler,
}: SelectAccountProps): JSX.Element {
  const { getLinkByName, origins } = useEnvironment();
  const [isSubmitting, setSubmitting] = useState<number | null>(null);

  const onAdminClick = useCallback(() => {
    window.location.href = `${origins.go}/admin/users/`;
  }, [origins]);

  const onAccountClick = useCallback(async (uid: number, methods: string[], method: string) => {
    if (isSubmitting) {
      return;
    }

    setSubmitting(uid);
    await selectHandler(uid, methods, method);
    setSubmitting(null);
  }, [setSubmitting, selectHandler, isSubmitting]);

  const ListComponent = accountsList.length > ACCOUNTS_COUNT_FOR_SELECT_VIEW
    ? AccountsSelect
    : AccountsList;

  return (
    <>
      <StyledContentBox>
        <H1>Choose Workspace</H1>
        <ListComponent
          accountsList={accountsList}
          errorMessage={errorMessage}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          isShowAdminPanel={isShowAdminPanel}
          onAdminClick={onAdminClick}
          onAccountClick={onAccountClick}
        />
      </StyledContentBox>
      <Backlinks>
        <Link title="Log out" href={getLinkByName('login')}>Log out</Link>
      </Backlinks>
    </>
  );
}
