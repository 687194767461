import React from 'react';

import { Spinner } from '@pushwoosh/kit-spinner';
import { ArrowIcon } from '@pushwoosh/kit-icons';

import { H4 } from '~/src/ui-kit';
import { ErrorCard } from '~/src/components/ErrorCard';
import { AccountsSkeleton } from '../AccountsSkeleton';
import { AccountTooltip } from '../AccountTooltip';
import { AccountBox, ListBox } from './AccountsList.styles';

import { SelectComponentProps } from '../../SelectAccount.types';

export function AccountsList(props: SelectComponentProps): JSX.Element {
  const {
    accountsList,
    errorMessage,
    isLoading,
    isSubmitting,
    isShowAdminPanel,
    onAdminClick,
    onAccountClick,
  } = props;

  return (
    <>
      <ListBox>
        {isShowAdminPanel && (
          <AccountBox onClick={onAdminClick}>
            <H4>Admin Panel</H4>
            <ArrowIcon direction="right" size="small" />
          </AccountBox>
        )}
        {isLoading && <AccountsSkeleton />}
        {accountsList.map(({
          uid,
          name,
          allowSelect,
          methods,
          method,
        }) => (
          <AccountTooltip key={uid} isAllowSelect={allowSelect}>
            <AccountBox
              disabled={!allowSelect}
              isSubmitting={!!isSubmitting}
              onClick={(): void => onAccountClick(uid, methods, method)}
            >
              <H4>{name}</H4>
              {
                isSubmitting === uid
                  ? <Spinner size="small" />
                  : <ArrowIcon direction="right" size="small" />
              }
            </AccountBox>
          </AccountTooltip>
        ))}
      </ListBox>
      {!!errorMessage && <ErrorCard message={errorMessage} />}
    </>
  );
}
