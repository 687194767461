import React, { PropsWithChildren } from 'react';

import { LoaderBox } from './ButtonLoader.styles';

export function ButtonLoader({ children }: PropsWithChildren): JSX.Element {
  return (
    <LoaderBox>
      {children}
    </LoaderBox>
  );
}
