import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useEnvironment } from '~/src/contexts/environment';
import { InvitePage } from './InvitePage.view';

import type { InviteFormValues } from './InvitePage.types';

export function InvitePageWithSSO(): JSX.Element {
  const navigate = useNavigate();
  const { getLinkByName } = useEnvironment();
  const { token = '' } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [action, setAction] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');

  const fetchData = useCallback(() => {
    const payload = new URLSearchParams();
    payload.append('code', token);

    return fetch(
      '/api/validate-invite',
      {
        method: 'POST',
        body: payload,
      },
    )
      .then((res) => {
        if (res.status === 200) {
          res.json()
            .then(({ validity, must, email }) => {
              if (validity) {
                setAction(must);
                setInviteEmail(email);
              } else {
                navigate(getLinkByName('login'));
              }
            });
        } else {
          navigate(getLinkByName('login'));
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }, [token, navigate, getLinkByName]);

  const acceptInvite = useCallback(() => {
    const payload = new URLSearchParams();
    payload.append('code', token);

    return fetch(
      '/api/accept-invite',
      {
        method: 'POST',
        body: payload,
      },
    )
      .then((res) => {
        if (res.status === 200) {
          navigate(getLinkByName('selectAccount'));
        } else {
          res.json()
            .then(({ message }) => {
              setErrorMessage(message);
            });
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }, [token, navigate, getLinkByName]);

  useEffect(() => {
    setLoading(true);
    fetchData()
      .then(() => setLoading(false));
  }, [token, fetchData]);

  const inviteHandler = useCallback(async ({ inviteOption }: InviteFormValues) => {
    switch (inviteOption) {
      case 'current':
        await acceptInvite();
        break;
      case 'login':
        navigate(`${getLinkByName('login')}?invite=${token}`);
        break;
      case 'register':
        navigate(`${getLinkByName('register')}?invite=${token}`);
        break;
      default:
        navigate(`${getLinkByName('login')}?invite=${token}`);
        break;
    }
  }, [getLinkByName, navigate, acceptInvite, token]);

  return (
    <InvitePage
      isLoading={isLoading}
      errorMessage={errorMessage}
      must={action}
      email={inviteEmail}
      inviteHandler={inviteHandler}
    />
  );
}
