import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { TextInputField } from '@pushwoosh/kit-text-input';

import { useCountdown } from '~/src/hooks/useCountdown';
import { useEnvironment } from '~/src/contexts/environment';
import { validateEmail } from '~/src/helpers/validation';
import {
  H1,
  FieldBox,
  Button,
  RouterLink,
} from '~/src/ui-kit';
import { Backlinks } from '~/src/components/Backlinks';
import { ContentBox } from '~/src/components/ContentBox';
import { ErrorCard } from '~/src/components/ErrorCard';
import { ButtonLoader } from '~/src/components/ButtonLoader';

import {
  FormStyled,
  HeaderBox,
  MessageBox,
  InputFieldsBox,
} from './ForgotPassword.styles';
import type { ForgotPasswordFormValues, ForgotPasswordProps } from './ForgotPassword.types';

export function ForgotPassword({
  errorMessage,
  initialEmail,
  sendTokenHandler,
  resultMessage,
}: ForgotPasswordProps): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      resetEmail: initialEmail,
    },
  });

  const { waitSeconds, setCountStartDate } = useCountdown(60);
  const { getLinkByName, productName } = useEnvironment();
  const isAllowRegister = productName === 'Pushwoosh';

  const onSendClick = useCallback(
    (payload: ForgotPasswordFormValues) => sendTokenHandler(payload)
      .then(() => setCountStartDate(Date.now())),
    [sendTokenHandler, setCountStartDate],
  );

  return (
    <>
      <ContentBox>
        <FormStyled onSubmit={handleSubmit(onSendClick)}>
          <HeaderBox>
            <H1>Password Recovery</H1>
            {resultMessage && <MessageBox>{resultMessage}</MessageBox>}
          </HeaderBox>
          <InputFieldsBox>
            {
              !resultMessage
              && (
              <FieldBox
                title="Email"
                isErrored={!!errors.resetEmail}
                helpText={errors.resetEmail && 'Please enter valid email'}
              >
                <TextInputField
                  $isErrored={!!errors.resetEmail}
                  $withIcon={false}
                  autoFocus
                  {...register('resetEmail', { required: true, validate: validateEmail })}
                />
              </FieldBox>
              )
            }
            {!!errorMessage && !isSubmitting && <ErrorCard message={errorMessage} />}
          </InputFieldsBox>
          {
            resultMessage && waitSeconds
              ? <ButtonLoader>{`Please wait ${waitSeconds} seconds to resend email...`}</ButtonLoader>
              : (
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  color="primary"
                  width="100%"
                >
                  Send Password Token
                </Button>
              )
          }
        </FormStyled>
      </ContentBox>
      <Backlinks>
        <RouterLink title="Sign in" to={getLinkByName('login')}>Sign in</RouterLink>
        {' '}
        to another account
        {isAllowRegister && (
          <>
            {' '}
            or
            {' '}
            <RouterLink title="Sign up" to={getLinkByName('register')}>Sign up</RouterLink>
          </>
        )}
      </Backlinks>
    </>
  );
}
