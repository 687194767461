export const PW_EMAIL_SUBSCRIPTION_APP_CODE = '6D382-5ADFE';

declare global {
    type Api = {
      apiClient: {
        postEvent: (payload: Record<string, unknown>) => void
      };
      postEvent: (event: string, attributes: Record<string, unknown>) => void;
      getTags: () => Promise<{
        result: Record<string, unknown>;
      }>;
      setTags: (tags: Record<string, unknown>) => void;
    };

    type Callback = (api: Api) => void;

    interface Window {
      Pushwoosh: {
        push: (callback: Callback) => void;
      };
    }
}

export const sendPostEventToEmailSubscriptionApplication = (email: string, event: string, attributes: Record<string, unknown>): void => {
  window.Pushwoosh = window.Pushwoosh || [];
  window.Pushwoosh.push((api) => {
    api.apiClient.postEvent({
      application: PW_EMAIL_SUBSCRIPTION_APP_CODE,
      hwid: email,
      userId: email,
      event,
      attributes,
    });
  });
};
