export function maskEmail(email: string = ''): string {
  const [name, domain] = email.split('@');

  if (!name || !domain) {
    return email;
  }

  const maskedName = name.length > 2
    ? `${name[0]}***${name[name.length - 1]}`
    : `${name[0]}*`;

  return `${maskedName}@${domain}`;
}
