import React from 'react';
import { createRoot } from 'react-dom/client';

import { initMixpanelSession } from './helpers/mixpanel';
import { updateHTML } from './helpers/html';

import { App } from './app';
import { initOpenReplaySession } from './helpers/openReplay';

initMixpanelSession();
initOpenReplaySession();
updateHTML();

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<App />);
