import React, {
  useState,
  useEffect,
  useCallback,
  type ReactElement,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Spinner } from '@pushwoosh/kit-spinner';
import { Horizontal } from '@pushwoosh/kit-helpers';
import {
  getCountryCodeByIp,
  checkIsAvailableCountyCode,
  type CountryCode,
} from '@pushwoosh/geo-ip';

import { useEnvironment } from '~/src/contexts/environment';

import { InviteSignUp } from './InviteSignUp.view';

export function InviteSignUpWithSSO(): ReactElement {
  const navigate = useNavigate();
  const { token = '' } = useParams();
  const { getApiOrigin, getLinkByName } = useEnvironment();

  const [inviteEmail, setInviteEmail] = useState('');
  const [isLoadingInvite, setIsLoadingInvite] = useState(false);
  const [countryCode, setCountryCode] = useState<CountryCode | null>(null);
  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const acceptInvite = useCallback(async () => {
    const payload = new URLSearchParams();
    payload.append('code', token);

    try {
      const res = await fetch(
        `${getApiOrigin()}/api/accept-invite`,
        {
          method: 'POST',
          body: payload,
        },
      );

      if (res.status !== 200) {
        const { message } = await res.json();
        setErrorMessage(message);
        return;
      }

      navigate(getLinkByName('selectAccount'));
    } catch (error: any) {
      setErrorMessage('Error inviting the user');
    }
  }, [token, navigate, getApiOrigin, getLinkByName]);

  const fetchData = useCallback(async () => {
    setIsLoadingInvite(true);

    try {
      const payload = new URLSearchParams();
      payload.append('code', token);

      const res = await fetch(
        `${getApiOrigin()}/api/validate-invite`,
        {
          method: 'POST',
          body: payload,
        },
      );

      if (res.status !== 200) {
        navigate(getLinkByName('login'));
        return;
      }

      const { validity, must, email } = await res.json();

      if (!validity) {
        navigate(getLinkByName('login'));
        return;
      }

      if (must === 'login') {
        navigate(`${getLinkByName('login')}?invite=${token}`);
        return;
      }

      if (must === 'accept') {
        await acceptInvite();
        return;
      }

      if (must === 'register') {
        setInviteEmail(email);
        return;
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsLoadingInvite(false);
    }
  }, [token, navigate, getApiOrigin, getLinkByName, acceptInvite]);

  const getCountryCode = useCallback(async () => {
    setIsLoadingCountry(true);

    try {
      const code = await getCountryCodeByIp();

      if (checkIsAvailableCountyCode(code)) {
        setCountryCode(code);
      }
    } finally {
      setIsLoadingCountry(false);
    }
  }, []);

  useEffect(() => {
    Promise.all([
      fetchData(),
      getCountryCode(),
    ]);
  }, [token, fetchData, getCountryCode]);

  if (isLoadingInvite) {
    return (
      <Horizontal $alignItems="center" $justifyContent="center">
        <Spinner size="medium" />
      </Horizontal>
    );
  }

  return (
    <InviteSignUp
      email={inviteEmail}
      inviteCode={token}
      countryCode={countryCode}
      isLoadingCountry={isLoadingCountry}
      globalError={errorMessage}
    />
  );
}
