import { v4 as uuidv4 } from 'uuid';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getCookie, setCookie } from './cookies';
import { useEnvironment } from '../contexts/environment';
import { getMixpanelDistinctId, trackMixpanelEvent } from './mixpanel';
import { validateEmail } from './validation';
import { isUserActionsTrackingOff } from './app.helpers';

interface QueryParams {
  [key: string]: string;
}

interface MetricAttributes {
  ln: string;
  href?: string | null;
  acc_id?: number | null;
  app_id?: string;
  ref?: string;
  utm?: QueryParams;
  queryParams?: QueryParams;
  clickedText?: string | null;
  n_user?: boolean;
}

interface MetricData {
  user_id?: string | null;
  action_type: string;
  attributes: MetricAttributes;
}

let userEmail: string | undefined;

function getPage(): string {
  return `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`;
}

function getQueryParams(queryString: string = window.location.search): { utm: QueryParams, queryParams: QueryParams } {
  const utm: QueryParams = {};
  const queryParams: QueryParams = {};
  if (queryString === '') {
    return { utm, queryParams };
  }

  const query = queryString.startsWith('?') ? queryString.substring(1) : queryString;

  query.split('&').forEach((pair) => {
    const [key, value] = pair.split('=');
    if (key !== undefined && value !== undefined) {
      const paramName = decodeURIComponent(key.replace(/\+/g, ' '));
      const paramValue = decodeURIComponent(value.replace(/\+/g, ' '));

      if (paramName.startsWith('utm_')) {
        utm[paramName] = paramValue;
      } else {
        queryParams[paramName] = paramValue;
      }
    }
  });

  return { utm, queryParams };
}

let userId: string | null = null;
const ln: string = navigator.language;

export const useUserMetricsCollector = (): { subscribe: () => void, unsubscribe: () => void } => {
  const { getApiOrigin } = useEnvironment();
  const location = useLocation();

  const sendMetrics = useCallback((data: MetricData): void => {
    if (process.env['NODE_ENV'] !== 'production' || isUserActionsTrackingOff()) {
      return;
    }

    const urlWithoutParams = getPage();

    trackMixpanelEvent({
      name: data.action_type,
      properties: {
        page: urlWithoutParams,
        user_id: data.user_id,
        ...data.attributes,
      },
    });

    const serializedData = JSON.stringify({
      page: urlWithoutParams,
      ...data,
      attributes: JSON.stringify({
        ...data.attributes,
        ...(userEmail ? { userEmail } : {}),
      }),
    });

    navigator.sendBeacon(`${getApiOrigin('user-action')}/api/v1/statistics/user-action/collect`, serializedData);
  }, [getApiOrigin]);

  useEffect(() => {
    userId = getCookie('user_id');
    const isFirstTimeVisit = !userId;

    if (!userId) {
      userId = uuidv4();
      setCookie('user_id', userId!, 8760, '/', '.pushwoosh.com');
    }

    const { queryParams, utm } = getQueryParams();
    const mixpanelDistinctId = getMixpanelDistinctId();
    if (mixpanelDistinctId && validateEmail(mixpanelDistinctId) === true) {
      userEmail = mixpanelDistinctId;
    }

    sendMetrics({
      user_id: userId,
      action_type: 'PAGE_VISIT',
      attributes: {
        ln,
        ...(Object.keys(utm).length && { utm }),
        ...(Object.keys(queryParams).length && { queryParams }),
        ref: document.referrer,
        ...(isFirstTimeVisit && { n_user: isFirstTimeVisit }),
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onClick = useCallback((event: MouseEvent): void => {
    const target = event.target as HTMLElement;

    const link = target.closest('a');
    const button = target.closest('button');

    const isButtonClicked = target.tagName === 'BUTTON' || button !== null;
    const isLinkClicked = link !== null;

    if (isButtonClicked || isLinkClicked) {
      const actionType: string = 'CLICK';
      const href = isLinkClicked ? link.href : null;

      sendMetrics({
        user_id: userId,
        action_type: actionType,
        attributes: {
          ln, ...(isLinkClicked && { href }), clickedText: target.textContent,
        },
      });
    }
  }, [sendMetrics]);

  const subscribe = useCallback((): void => {
    window.addEventListener('click', onClick);
  }, [onClick]);

  const unsubscribe = useCallback((): void => {
    window.removeEventListener('click', onClick);
  }, [onClick]);

  return useMemo(() => ({ subscribe, unsubscribe }), [subscribe, unsubscribe]);
};
