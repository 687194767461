import React, { useMemo, type ReactElement } from 'react';

import {
  StyledAccountBox,
  StyledTitle,
  StyledIcon,
} from './AccountsSkeleton.styles';

export function AccountsSkeleton(): ReactElement {
  const titlesWidth = useMemo(() => ['100px', '130px', '80px'], []);

  return (
    <>
      {titlesWidth.map((width) => (
        <StyledAccountBox key={width}>
          <StyledTitle width={width} />
          <StyledIcon />
        </StyledAccountBox>
      ))}
    </>
  );
}
