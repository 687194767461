import styled from 'styled-components';

const borderRadius = '4px';

export const RootBox = styled.div`
  display: flex;
  border-radius: ${borderRadius};
  background: rgba(255, 153, 177, 0.14);
  align-items: stretch;
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  background-color: #E52E59;
  border-radius: ${borderRadius} 0 0 ${borderRadius};
  padding: 20px 8px;
  color: #fff;
`;

export const MessageBox = styled.span`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-weight: 500;
`;
