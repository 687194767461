import React from 'react';

import {
  StyledRadioContainer,
  StyledRadioInput,
  StyledLabel,
} from './RadioButton.styles';

import type { RadioButtonProps } from './RadioButton.types';

export function RadioButton(props: RadioButtonProps): JSX.Element {
  const {
    inputRef,
    label = '',
    value = '',
    name = '',
    checked = false,
    onBlur,
    onChange,
  } = props;

  return (
    <StyledRadioContainer>
      <StyledRadioInput
        ref={inputRef}
        id={value}
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <StyledLabel htmlFor={value} checked={checked}>{label}</StyledLabel>
    </StyledRadioContainer>
  );
}
