import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
} from 'react';
import type { PropsWithChildren } from 'react';

import { initialState, reducer } from './sessionStore.reducer';
import { SessionActionTypes } from './sessionStore.types';
import type { TSessionStore } from './sessionStore.types';

const SessionContext = createContext<TSessionStore | null>(null);

export function SessionProvider({ children }: PropsWithChildren): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);

  const ProviderValues = useMemo(() => ({
    state: { ...state },
    setConfirmationAccount: (accountID: number): void => {
      dispatch({ type: SessionActionTypes.SET_CONFIRM_ACCOUNT, payload: accountID });
    },
    setIsAdmin: (): void => {
      dispatch({ type: SessionActionTypes.SET_IS_ADMIN, payload: {} });
    },
    setAuthOptions: (methods: string[]): void => {
      dispatch({ type: SessionActionTypes.SET_AUTH_OPTIONS, payload: methods });
    },
    setAuthMethod: (method: string): void => {
      dispatch({ type: SessionActionTypes.SET_AUTH_METHOD, payload: method });
    },
  }), [state, dispatch]);

  return (
    <SessionContext.Provider value={ProviderValues}>
      {children}
    </SessionContext.Provider>
  );
}

export function useSession(): TSessionStore {
  const session = useContext(SessionContext);
  if (!session) {
    throw new Error('SessionContext is not defined! Please check what are you using useSession in a child of SessionProvider!');
  }

  return session;
}
