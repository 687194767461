import React, { type ReactElement } from 'react';

import { RecaptchaElement } from './Recaptcha.style';
import { RECAPTCHA_CONTAINER_ID } from './Recaptcha.constants';

export function ReCAPTCHA(): ReactElement {
  return (
    <RecaptchaElement id={RECAPTCHA_CONTAINER_ID} />
  );
}
