import React, { useState, useCallback, type ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@pushwoosh/kit-button';
import { Color } from '@pushwoosh/kit-constants';
import { Horizontal, Vertical } from '@pushwoosh/kit-helpers';
import { Text } from '@pushwoosh/kit-typography';
import { Checkbox } from '@pushwoosh/kit-checkbox';
import { FieldBox } from '@pushwoosh/kit-field-box';
import { Spinner } from '@pushwoosh/kit-spinner';
import { TextInputField } from '@pushwoosh/kit-text-input';
import { getCountryName } from '@pushwoosh/geo-ip';

import { useEnvironment } from '~/src/contexts/environment';
import { getCookie } from '~/src/helpers/cookies';
import { maskEmail } from '~/src/helpers/mask';
import { H1, H3, Paragraph } from '~/src/ui-kit';
import { ButtonLoader } from '~/src/components/ButtonLoader';
import { ContentBox } from '~/src/components/ContentBox';
import { GoogleLogo } from '~/src/components/GoogleLogo';
import { ReCAPTCHA, useRecaptcha } from '~/src/components/Recaptcha';
import { Separator } from '~/src/components/Separator';
import { ErrorCard } from '~/src/components/ErrorCard';

import type { InviteSignUpProps, FormValues } from './InviteSignUp.types';

export function InviteSignUp(props: InviteSignUpProps): ReactElement {
  const {
    email,
    inviteCode,
    countryCode,
    isLoadingCountry,
    globalError = '',
  } = props;

  const { getApiOrigin, productName } = useEnvironment();
  const { getRecaptchaToken } = useRecaptcha(productName);
  const isAllowGoogleSignUp = productName === 'Pushwoosh';

  const [errorMessage, setErrorMessage] = useState(globalError);

  const {
    watch,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      password: '',
      confirmPassword: '',
      allowMarketing: false,
    },
  });

  const validatePasswords = useCallback(() => {
    const { password, confirmPassword } = getValues();

    return (!!password && password === confirmPassword) || 'Passwords must match';
  }, [getValues]);

  const onSubmit = useCallback(async (values: FormValues) => {
    const { allowMarketing, password } = values;
    const referralHash = getCookie('__PW_REFERRAL_PROGRAM_CODE__');

    try {
      const recaptchaResponse = await getRecaptchaToken();
      await fetch(`${getApiOrigin('user-api')}/register`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          recaptchaResponse,
          email,
          password,
          invite: inviteCode,
          subscribe: +allowMarketing,
          country: countryCode ? getCountryName(countryCode) : '',
          countryCode,
          ...referralHash && { referral_hash: referralHash },
        }),
      });
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }, [
    email,
    inviteCode,
    countryCode,
    getApiOrigin,
    getRecaptchaToken,
  ]);

  return (
    <ContentBox>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Vertical $gap={24}>
          <Vertical $gap={4}>
            <H1>Sign up to accept invite</H1>
            <Paragraph>
              To&nbsp;access the invited workspace, please fill in&nbsp;the required fields for your
              <strong>{` ${maskEmail(email)} `}</strong>
              login.
            </Paragraph>
          </Vertical>
          <Vertical $gap={16}>
            <Vertical $gap={4}>
              <H3>Create a password</H3>
              <Paragraph>
                Your password must include an&nbsp;uppercase letter, a&nbsp;lowercase letter,
                a&nbsp;number, and a&nbsp;special character.
              </Paragraph>
            </Vertical>
            <FieldBox
              title="Password"
              footer={errors.password && (
                <Text $variant="footnote" $color={Color.DANGER}>
                  {errors.password.message}
                </Text>
              )}
            >
              <TextInputField
                autoFocus
                type="password"
                $isErrored={!!errors.password}
                {...register('password', {
                  required: 'Please enter password',
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters long',
                  },
                })}
              />
            </FieldBox>
            <FieldBox
              title="Repeat Password"
              footer={errors.confirmPassword && (
                <Text $variant="footnote" $color={Color.DANGER}>
                  {errors.confirmPassword.message}
                </Text>
              )}
            >
              <TextInputField
                autoFocus
                type="password"
                $isErrored={!!errors.confirmPassword}
                {...register('confirmPassword', {
                  required: 'Please repeat your password',
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters long',
                  },
                  validate: validatePasswords,
                })}
              />
            </FieldBox>
          </Vertical>
          <Checkbox
            name="allowMarketing"
            value={watch('allowMarketing')}
            isChecked={watch('allowMarketing')}
            onChange={(): void => setValue('allowMarketing', !watch('allowMarketing'))}
          >
            I&nbsp;would like to&nbsp;receive service updates, news and announcements
          </Checkbox>
          <ReCAPTCHA />
          {(errorMessage && !isSubmitting) && <ErrorCard message={errorMessage} />}
          <Vertical $gap={12}>
            {isLoadingCountry
              ? (
                <ButtonLoader>
                  <Spinner size="small" />
                  Getting everything ready...
                </ButtonLoader>
              ) : (
                <>
                  <Button
                    type="submit"
                    width="100%"
                    color="primary"
                    isLoading={isSubmitting}
                  >
                    Sign up
                  </Button>
                  {isAllowGoogleSignUp && (
                    <>
                      <Separator />
                      {isSubmitting ? (
                        <ButtonLoader>
                          <Horizontal $alignItems="center" $gap={6}>
                            <GoogleLogo opacity="50%" />
                            Sign In With Google
                          </Horizontal>
                        </ButtonLoader>
                      ) : (
                        <Button
                          as="a"
                          type="button"
                          color="secondary"
                          width="100%"
                          href={inviteCode === '' ? '/api/auth-with-google' : `/api/auth-with-google?invite=${inviteCode}`}
                        >
                          <Horizontal $alignItems="center" $gap={6}>
                            <GoogleLogo />
                            Sign In With Google
                          </Horizontal>
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
          </Vertical>
        </Vertical>
      </form>
    </ContentBox>
  );
}
