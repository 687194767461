import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useAPI } from '~/src/hooks/useAPI';
import { sendToGoogleTagManager } from '~/src/helpers/sendToGoogleTagManager';
import { useEnvironment } from '~/src/contexts/environment';

import { CreatePassword } from './CreatePassword.view';
import { SuccessPage } from './components/SuccessPage';

import type { CreatePasswordFormValues } from './CreatePassword.types';

export function CreatePasswordWithSSO(): JSX.Element {
  const { callAPI } = useAPI();
  const [errorMessage, setErrorMessage] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const { token, email, account } = useParams();
  const { getApiOrigin } = useEnvironment();

  const createPasswordHandler = useCallback(
    ({ password, confirmPassword, allowMarketing }: CreatePasswordFormValues) => fetch(
      `${getApiOrigin('user-api')}/create-password`,
      {
        method: 'POST',
        body: JSON.stringify({
          token,
          email,
          password,
          password_confirm: confirmPassword,
          subscribe: +allowMarketing,
          ...account && { account_id: +account },
        }),
      },
    )
      .then(async (r) => {
        const { message } = await r.json() as { message: string };

        if (r.status === 200) {
          sendToGoogleTagManager({ event: 'user_activated' });
          await callAPI(
            'api/activate',
            {
              email,
              ...account && { account_id: +account },
              code: token,
            },
          )
            .then((response) => {
              const { redirect } = response;

              if (redirect) {
                redirect();
                return;
              }

              setResultMessage(message);
            });
        } else {
          throw new Error(message);
        }
      })
      .catch((error) => setErrorMessage(error.message)),
    [account, email, token, getApiOrigin, callAPI],
  );

  return (
    resultMessage
      ? <SuccessPage />
      : <CreatePassword errorMessage={errorMessage} createPasswordHandler={createPasswordHandler} />
  );
}
