import styled from 'styled-components';

export const InfoNote = styled.p<{ align?: string }>`
  margin-bottom: 0;
  color: #969CA3;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;

  text-align: ${(props): string => props.align || 'left'};
`;
