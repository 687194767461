import styled from 'styled-components';

const borderRadius = '4px';

export const RootBox = styled.div`
  display: flex;
  border-radius: ${borderRadius};
  background: rgba(153, 201, 255, 0.14);
  align-items: center
`;

export const IconBox = styled.div`
  height: 100%;
  background-color: rgba(153, 201, 255, 0.2);
  border-radius: ${borderRadius} 0 0 ${borderRadius};
  padding: 20px 8px;
  color: #00A2FF;
`;

export const MessageBox = styled.span`
  padding: 8px 12px;
`;
