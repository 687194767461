import React, { useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useSession } from '~/src/contexts/sessionStore';
import { useAPI, PostApiReponsePayload } from '~/src/hooks/useAPI';

import { TwoFactorCode } from './TwoFactorCode.view';

import type { ConfirmCodeFormValues } from './TwoFactorCode.types';

export function TwoFactorCodeWithSSO(): JSX.Element {
  const { callAPI } = useAPI();
  const session = useSession();
  const { confirmAccountID, authOptions, authMethod } = session.state;
  const [errorMessage, setErrorMessage] = useState('');

  const confirmHandler = useCallback(({
    confirmationCode,
    isTrusted,
  }: ConfirmCodeFormValues): Promise<void> => {
    setErrorMessage('');

    return callAPI(
      'api/confirmation-code',
      {
        method: authMethod,
        passcode: `${confirmationCode}`,
        account_id: confirmAccountID,
        trusted: isTrusted,
      },
    )
      .then((response) => {
        const { redirect, message } = response as PostApiReponsePayload;

        // awaiting redirect and ok response will be errored as unexpected behavior
        return redirect ? redirect() : setErrorMessage(message);
      })
      .catch((error) => setErrorMessage(error.message));
  }, [callAPI, confirmAccountID, authMethod]);

  const sendCodeHandler = useCallback(() => (
    callAPI('api/two-factor-auth-send-code', { account_id: confirmAccountID })
      .then((response) => {
        const { redirect, message } = response as PostApiReponsePayload;

        return redirect ? redirect() : setErrorMessage(message);
      })
      .catch((error) => setErrorMessage(error.message))
  ), [callAPI, confirmAccountID]);

  return (
    confirmAccountID
      ? (
        <TwoFactorCode
          authMethod={authMethod}
          authOptions={authOptions}
          sendCodeHandler={sendCodeHandler}
          confirmHandler={confirmHandler}
          errorMessage={errorMessage}
          isTrustedOption
        />
      )
      : <Navigate to="/select-account" />
  );
}
