import React from 'react';

import { H1 } from '~/src/ui-kit';

import { MessageCard } from '~/src/components/MessageCard';
import { StyledContentBox } from './SuccessPage.styles';

export function SuccessPage({ email }: { email: string }): JSX.Element {
  return (
    <StyledContentBox>
      <H1>Check Your Email</H1>
      <MessageCard>
        You will receive a confirmation email at
        {` ${email} `}
        in a few minutes.
      </MessageCard>
    </StyledContentBox>
  );
}
