import React from 'react';

import { LogoProps } from './Logo.types';

export function OmoLogo({
  width = 80,
  height = 80,
}: LogoProps): JSX.Element {
  return (
    <svg style={{ display: 'block' }} width={width} height={height} viewBox="0 0 73 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4598 15.1437C11.4678 15.1613 12.8333 17.645 15.3127 21.5238C15.3376 21.447 15.3624 21.3707 15.3841 21.2855C18.4734 7.06997 29.617 7.64641 29.617 7.64641C34.8668 7.64641 39.1707 10.8875 40.4421 16.8355C40.6377 17.5099 40.7965 18.4757 40.8364 19.8227L40.8391 19.8214C40.8391 19.8214 40.8373 19.8307 40.8364 19.8351C40.8457 20.1472 40.8475 20.4813 40.8426 20.8361C40.945 21.3949 41.267 21.9934 42.1331 22.1139C43.5806 22.0857 45.3572 22.1179 46.6299 22.1391C47.5639 19.7865 48.3213 17.3444 48.8668 14.8837C49.0012 14.2768 48.8943 14.9751 49.0003 14.3867C48.3502 14.4057 47.5058 14.5443 47.0441 14.4573C45.2902 5.80321 38.4536 0.139431 29.617 0.139431C20.995 0.139431 13.6102 6.5412 11.4603 15.1428C11.4851 15.1357 11.5095 15.1296 11.4598 15.1437Z" fill="#0F4DBA" />
      <path d="M48.8669 14.8837C48.3214 17.344 47.564 19.7866 46.63 22.1387C46.5711 22.2883 46.5178 22.4406 46.4571 22.5893C43.4694 29.8964 37.8914 36.5917 32.0455 39.4324H32.6615C32.6615 39.4324 33.3285 39.3874 34.2035 39.3242C41.8986 38.3757 51.8816 25.5907 55.5948 13.3226L49.0022 14.3753C49.0013 14.3797 49.0008 14.3823 49.0004 14.3867C48.8944 14.9751 49.0013 14.2768 48.8669 14.8837Z" fill="#FF9500" />
      <path d="M34.2034 39.3241C35.7187 39.2146 37.858 39.0518 38.2421 38.9772L39.1485 38.7852C41.7198 38.2096 43.7992 37.4191 45.453 36.5677C47.3971 35.3579 49.3648 33.6974 51.2589 31.6957C51.2962 31.6344 51.3152 31.5991 51.3152 31.5991L51.3254 31.6269C56.879 25.7296 61.7719 16.9206 63.4841 8.00868C63.4864 7.99632 63.489 7.98087 63.4912 7.96807L56.6449 8.90953C56.4688 9.89867 56.2391 10.9981 55.9708 12.0058C55.8542 12.4437 55.7278 12.8828 55.5947 13.3224C51.8815 25.5906 41.899 38.376 34.2034 39.3241Z" fill="#0F4DBA" />
      <path d="M63.9047 4.67924C63.8036 5.50242 63.6492 7.1214 63.4909 7.9684C63.4887 7.98076 63.4865 7.99665 63.4843 8.00857C61.772 16.9205 56.8792 25.7295 51.3256 31.6268L51.3154 31.599C51.3154 31.599 51.2963 31.6343 51.259 31.6956C49.3645 33.6977 47.3973 35.3578 45.4531 36.5676C43.7994 37.419 41.7199 38.2095 39.1482 38.7851L38.2418 38.9771C37.8582 39.0517 35.7184 39.215 34.2035 39.324C33.3285 39.3871 32.6616 39.4326 32.6616 39.4326H32.0451H16.7488L13.1141 47.3765H28.7115V47.3774H30.0916C30.0916 47.3774 41.9638 47.6978 48.6465 44.5058C52.5651 42.4896 56.3967 39.3589 59.7072 35.2743C65.8812 27.6575 71.5754 13.0032 72.2668 3.03511L63.9047 4.67924Z" fill="#D42128" />
      <path d="M14.4897 39.3803C10.3924 38.9239 7.19274 35.3761 7.19274 31.0775C7.19274 27.0826 9.95691 23.7383 13.636 22.9182V22.9155C13.636 22.9155 13.6404 22.9164 13.6422 22.9164C13.7309 22.897 13.8209 22.8815 13.9105 22.8648L13.9096 22.8577C13.9096 22.8577 14.4285 22.8259 14.7837 22.4697C14.9837 22.2623 15.1713 21.9586 15.3123 21.5239C12.8333 17.6455 11.4678 15.1614 11.4598 15.1437C4.88269 17.009 0.0186768 23.4447 0.0186768 31.0775C0.0186768 39.3375 5.7142 46.1961 13.114 47.3768L16.7491 39.4324L14.4897 39.3803Z" fill="#FF9500" />
    </svg>
  );
}
