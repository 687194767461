import styled from 'styled-components';

export const HeaderBox = styled.div`
  > * + * {
    margin-top: 4px;
  }
`;

export const FormStyled = styled.form`
  > * + * {
    margin-top: 24px;
  }
`;

export const InputFieldsBox = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 16px;
  }
`;

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 8px;
  }
`;

export const OptionsBox = styled.div`
  text-align: center;
`;

export const CheckboxFootnote = styled.span`
  font-weight: 500;
  color: #969CA3;
`;
