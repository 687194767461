import React, {
  useState,
  useEffect,
  useCallback,
  type ReactElement,
} from 'react';

import { useSession } from '~/src/contexts/sessionStore';
import { useAPI, GetAccountDetailsResponse, PostApiReponsePayload } from '~/src/hooks/useAPI';

import { SelectAccount } from './SelectAccount.view';

import type { AccountsList } from './SelectAccount.types';

export function SelectAccountWithSSO(): ReactElement {
  const { callAPI } = useAPI();
  const session = useSession();

  const [accountsList, setAccountsList] = useState([] as AccountsList);
  const [isLoading, setLoadingState] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setLoadingState(true);

    callAPI('api/select-account', {}, 'GET')
      .then((response) => {
        const { accounts, redirect } = response as GetAccountDetailsResponse;

        if (redirect) {
          redirect();
          return;
        }

        setAccountsList(accounts.map(
          (account) => ({
            uid: account.id,
            name: account.name,
            allowSelect: account.isAllowSelect,
            isNeedTwoFactorAuth: account.isAllowTwoFactorAuth,
            methods: account.availableMethods,
            method: account.defaultMethod,
          }),
        ));
        setLoadingState(false);
      })
      .catch((error) => {
        setLoadingState(false);
        setErrorMessage(error.message);
      });
  }, [callAPI]);

  const selectAccountHandler = useCallback((
    uid: number,
    methods: string[],
    method: string,
  ): Promise<void> => {
    setErrorMessage('');

    return callAPI('api/select-account', { account_id: `${uid}` })
      .then((response) => {
        const { redirect, message } = response as PostApiReponsePayload;

        session.setConfirmationAccount(uid);
        session.setAuthOptions(methods);
        session.setAuthMethod(method);

        // awaiting redirect and ok response will be errored as unexpected behavior
        return redirect ? redirect() : setErrorMessage(message);
      })
      .catch((error) => setErrorMessage(error.message));
  }, [callAPI, session]);

  return (
    <SelectAccount
      accountsList={accountsList}
      errorMessage={errorMessage}
      isLoading={isLoading}
      selectHandler={selectAccountHandler}
    />
  );
}
