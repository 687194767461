import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useEnvironment } from '~/src/contexts/environment';

import { ChangePassword } from './ChangePassword.view';
import { SuccessPage } from './components/SuccessPage';

import type { ChangePasswordFormValues } from './ChangePassword.types';

export function ChangePasswordWithSSO(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const { token } = useParams();
  const { origins } = useEnvironment();

  const savePasswordHandler = useCallback(
    ({ password }: ChangePasswordFormValues) => fetch(
      `${origins.sso}/api/v1/accounts/resetPassword`,
      {
        method: 'POST',
        body: JSON.stringify({
          token,
          password,
        }),
      },
    )
      .then(async (r) => {
        const { message } = await r.json() as { message: string };

        if (r.status === 200) {
          setResultMessage(message);
        } else {
          throw new Error(message);
        }
      })
      .catch((error) => setErrorMessage(error.message)),
    [token, origins],
  );

  return (
    resultMessage
      ? <SuccessPage />
      : <ChangePassword errorMessage={errorMessage} savePasswordHandler={savePasswordHandler} />
  );
}
