import React, { type ReactElement } from 'react';

import { useEnvironment } from '~/src/contexts/environment';
import { PWLogo } from './PWLogo';
import { OmoLogo } from './OmoLogo';
import { LogoBox } from './ProductLogo.styles';

export function ProductLogo(): ReactElement {
  const { productName } = useEnvironment();

  return (
    <LogoBox>
      {productName === 'OMO' ? <OmoLogo /> : <PWLogo />}
    </LogoBox>
  );
}
