export enum ConfiguredEnv {
  INSTALLER = 'installer',
  STAGE = 'stage',
  PRODUCTION = 'prod',
}

export enum ApiNames {
  GO = 'go',
  SSO = 'sso',
  USER_API = 'userApi',
  USER_ACTIONS_API = 'userActionApi',
}

export const configuredEnvs = Object.values(ConfiguredEnv).filter((v) => Number.isNaN(Number(v)));

export const envPrefixes = {
  [ConfiguredEnv.INSTALLER]: '.pwi',
  [ConfiguredEnv.STAGE]: '.stage',
  [ConfiguredEnv.PRODUCTION]: '',
};

export const linksConfig = {
  go: {
    login: '/cp/login',
    selectAccount: '/cp/selectAccount',
    logout: '/logout',
    register: '/cp/register',
    resetPassword: '/cp/reset-password',
    confirm: '/cp/confirmationCode',
    invite: '/cp/invite',
    authOptions: '/cp/auth-options',
  },
  sso: {
    login: '/login',
    selectAccount: '/select-account',
    logout: '/logout',
    register: '/register',
    resetPassword: '/reset-password',
    confirm: '/confirm-code',
    invite: '/invite',
    authOptions: '/auth-options',
  },
};
